import Calendar from 'react-calendar';
import { styled } from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { BiTrash, BiPencil, BiLineChart } from "react-icons/bi/index.esm.js";
//import 'react-calendar/dist/Calendar.css';
//import { CircularProgressbar } from 'react-circular-progressbar';
import '../style/calendar.css';
import { Logout } from "../components/Logout.js";
import { Button } from "../components/buttons/button.js";
import 'react-circular-progressbar/dist/styles.css';
import { getNews } from "../redux/services/news.service.js";
import { getFood } from "../redux/services/food.service.js";
import { getSurvey } from "../redux/services/survey.service.js";
import ChildRegModal from "../components/modal/ChildRegModal.js";
import ChildEditModal from "../components/modal/ChildEditModal.js";
import { handleEditOrViewMobile } from "../redux/actions/login.action.js";
import { Wrapper, Logo, LogoImage, BackGroundImage } from "../style/styled.js";
import { getCompletedSurvey, childSurveyList } from "../redux/services/survey.service.js";
import { MobileHeader } from '../components/mobile/MobileHeader.js';
import { MobileFooter } from '../components/mobile/MobileFooter.js';
import { SectionHeader } from '../components/mobile/SectionHeader.js';

const MobileDashBoard = (props) => {
  const { user, completedSurvey } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isChild = (user?.age !== undefined)
  const [value, onChange] = useState(new Date());
  const [displayFood, setDisplayFood] = useState();
  const [show3DModel, toggle3DModel] = useState(false);
  const [showChildReg, toggleChildReg] = useState(false);
  const [selectedChild, setSelectedChild] = useState("");
  const [surveyList, setSurveyList] = useState();
  const [newsList, setNewsList] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [childSurvey, setChildSurvey] = useState();

  // if(isChild){
  //   if(user.age > 0 && user.age < 12){ limeSurveyUrl = "https://juniorchild.limesurvey.net/"}
  //   else if(user.age > 11 && user.age < 19){ limeSurveyUrl = "https://juniorchild.limesurvey.net/" }
  // }

  const handleEditChild = (item) => {
    dispatch(handleEditOrViewMobile(item, navigate))
  }

  const handleDeleteChild = (item) => {
    //dispatch(handleEditOrView(item, navigate))
  }

  const handleShowSurvey = async (item) => {
    const comSurvey = await childSurveyList(item.userId);
    setChildSurvey(comSurvey?.data?.data)
    setShowEditModal(true)
  }

  useEffect(()=>{
    value.setHours(0);
    value.setMinutes(0);
    value.setSeconds(0);
    value.setMilliseconds(0);
    if(user){
      const data = {
        user_id: user?._id,
        date: value
      } 
      getFood(data).then((response) => {
        setDisplayFood(response.data?.data)
      });
      getSurvey(data).then((response) => {
        setSurveyList(response.data?.data)
      });
      getNews(data).then((response) => {
        setNewsList(response.data?.data)
      });
    }
  }, [value, user?._id])
  return (
    <Wrapper style={{ flexDirection: "column" }}>
      <MobileHeader username={user?.name+" "+user?.surname} familycode={user?.familycode}/>
      <ContentWrapper>
        <SectionHeader>Abilita figli</SectionHeader>
        <ChildList>
                {
                  user?.children?.map((item, index)=>{
                    if(item.selected)
                      return(
                        <NameDiv key={index}>
                          <ChildName>{item.childname}</ChildName>
                          {item.qualified ? 
                            <Button name="Abilitato" style={{borderRadius: "10px", height: "36px", backgroundColor: "#408562", minWidth: "100px", padding: "0 10px", border: "1px solid #408562"}}/>: 
                            <Button name="Non abilitato" style={{borderRadius: "10px", height: "36px", backgroundColor: "#e43535", padding: "0 10px", minWidth: "100px",  border: "1px solid #e43535"}} onClick={()=>{setSelectedChild(item); toggleChildReg(true)}}/> 
                          }
                          <ActionDiv style={{minWidth:"80px"}}>
                            {item.qualified && <BiPencil onClick={(e)=>{handleEditChild(item);}} style={{cursor: "pointer"}}/>}
                            <BiTrash onClick={(e)=>{handleDeleteChild(item);}} style={{cursor: "pointer"}}/>
                            {item.qualified && <BiLineChart onClick={(e)=>{handleShowSurvey(item)}} style={{cursor: "pointer"}}/>}
                          </ActionDiv>
                        </NameDiv>
                      )
                    })
                }
                <ChildRegModal showModel={showChildReg} toggleModel={toggleChildReg} selectedChild={selectedChild} parentDetail={{"familyId": user?.familyId}}/>
                <ChildEditModal showModel={showEditModal} toggleModel={setShowEditModal} childSurvey={childSurvey}/>
                </ChildList>
        <SectionHeader>I miei progressi</SectionHeader>
      </ContentWrapper>
      <MobileFooter/>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.auth?.user,
    completedSurvey: state?.auth?.completedSurvey
  };
};
export default connect(mapStateToProps)(MobileDashBoard);

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const NameDiv = styled.div`
  width: 90%;
  padding: 1vh;
  height: 4vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ChildList = styled.div`
  height: 12.5vh;
`;
const ChildName = styled.div`
  min-width: 100px;
`;
const ActionDiv = styled.div`
  width: 15%;
  padding: 1vh;
  height: 4vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;