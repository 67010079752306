import axios from "axios";
import { store } from "../store/store.js";

export const axiosClient = (args = {}) => {
  // args.url = `${process.env.REACT_APP_BASE_URL}${args.url}`;
  //args.url = `http://192.168.1.57:3000/api${args.url}`;
  args.url = `${process.env.REACT_APP_BASE_URL_REMOTE}${args.url}`;
  let auth_token = localStorage.getItem("access_token"),
    authToken = store.getState().auth.token || auth_token,
    headers = args.headers ? args.headers : {};
  args.headers = {
    token: authToken,
    ...headers,
  };

  return axios(args);
};