import AppRoutes from "./routers/dupRoute.js";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
