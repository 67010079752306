import * as Yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Button } from "../components/buttons/button.js";
import { Wrapper, LoginForm } from "../style/styled.js";
import { reSendOtpToResetPasswordOnMail, verifyAccount } from "../redux/services/index.js";
import swal from "sweetalert";

const otpValidation = Yup.object().shape({
  otp: Yup.string().required("This field is required."),
});

const OneTimePassword = (props) => {
  //const [email, setEmail] = useState("");
  const [screen, setScreen] = useState("");
  const [dateValue, setDateValue] = useState(Date.now());
  const [reInit, setReInit] = useState(true);
  const [resendValue, setResendValue] = useState(true)
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const email = queryParameters.get("email");
  console.log(email)
  let myInput = null;
  useEffect(() => {
    if (location?.state?.email) {
      window.sessionStorage.setItem("emailer", location?.state?.email);
    }
    //setEmail(window.sessionStorage.getItem("emailer"));
    setScreen(location?.screen);
  }, []);

  const formik = useFormik({
    initialValues: {
      otp: "",
      submitButton: false,
    },
    validationSchema: otpValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = {
        email: email,
        otp: values.otp,
      };
      verifyAccount(data)
        .then((res) => {
          //toast.success(res?.data?.message);
          swal({
            text: res?.data?.message,
            timer: 5000
          });
          navigate(`/child-add/?user=${res?.data?.data?.token}`)
        })
        .catch((error) => {
          //toast.error(error?.response?.data?.message);
          swal({
            text: error?.response?.data?.message,
          });
        });
    },
  });
  const { handleSubmit, values, errors, setFieldValue } = formik;
  const handleResendOtp = () => {
    const data = {
      email: email,
      otp: "VERIFY_ACCOUNT",
    };
    setDateValue(Date.now()+3 * 60 * 1000)
    myInput.start();
    setReInit(true)
    setResendValue(false)
    reSendOtpToResetPasswordOnMail(data)
      .then((res) => {
        if (res === undefined) {
          //toast.error("Per favore riprova più tardi.");
          swal({
            text: "Per favore riprova più tardi."
          });
          return;
        }
        setResendValue(true)
        swal({
          text: res?.data?.message,
          timer: 5000
        });
        //toast.success(res?.data?.message);
      })
      .catch((error) => {
        //toast.error(error.response && error.response.data && error.response.data.message);
        swal({
          text: error?.response?.data?.message,
        });
        setResendValue(false)
      });
  };
  const customStyleForOtp = {
    border: "1px solid #3E434D",
    background: "#fff",
    height: "50px",
    width: "50px",
    color: "rgb(35,121,85)",
    outline: "none",
    padding: "0px",
    margin: "0  10px 0 0",
    boxSizing: "border-box",
    borderColor: "#eaecf5",
    borderRadius: "5px",
    fontSize: "18px",
  };

  const renderer = ({ minutes, seconds, completed, api }) => {
    if (completed) {
      setReInit(false)
      setDateValue(Date.now()+100*10*1000)
      api.start()
      return <span id="change">Otp expired..</span>;
    } else {
      return (
        <span id="change">
          Time: {minutes}:{seconds}
        </span>
      );
    }
  };

    const rendererExpired = ({ minutes, seconds, completed, api }) => {

      return <span id="change">Otp expired..</span>;

  };

  const onComplete = () => {
    setResendValue(false)
  };
  return (
    <Wrapper>
      {/* <BackgroundImg>
        <Logo src={"/images/logo.svg"} />
      </BackgroundImg> */}
      <LoginForm>
        <Innerwapper>
          <Heading>Password una tantum</Heading>
          <Text>
            Inserisci la One Time Password inviata a <b>{email}</b>
          </Text>
          <Form onSubmit={handleSubmit}>
            <div>
              <OtpInput
                type="text"
                value={values.otp}
                onChange={(otp) => {
                  setFieldValue("otp", otp);
                }}
                numInputs={6}
                inputStyle={customStyleForOtp}
                renderInput={(props) => <input {...props} />}
              />
              <Error style={{ marginTop: 10 }}>{errors.otp}</Error>
            </div>
            <Timer>
              {/* <Link>Resend OTP</Link> */}
              <Btnwapper>
                <Button
                  name="Invia"
                  disabled={values.submitButton}
                  type="submit"
                  style={{
                    background: values.submitButton ? "#007955a3" : "#007955",
                  }}
                />
                <Button
                  name="Invia nuovamente OTP"
                  disabled={resendValue}
                  type="button"
                  style={{
                    opacity: resendValue ? "0.5" : "1",
                  }}
                  className="resend-btn"
                  onClick={() => handleResendOtp()}
                />
              </Btnwapper>
              <div>
                {reInit === true ? <Countdown
                  ref={(ref) => (myInput = ref)}
                  date={dateValue + 3 * 60 * 1000}
                  renderer={renderer}
                  onComplete={() => {
                    onComplete();
                  }}
                />:<Countdown
                  ref={(ref) => (myInput = ref)}
                  date={ Date.now()+ 1 * 1 * 1000}
                  renderer={rendererExpired}
                  onComplete={() => {
                    onComplete();
                  }}
                />}
                
              </div>
            </Timer>
          </Form>
        </Innerwapper>
        <ToastContainer/>
      </LoginForm>
    </Wrapper>
  );
};

export default OneTimePassword;
const Error = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 15px;
  margin-top: -10px;
`;

// const Logo = styled.img`
//   width: 175px;
//   height: auto;
//   margin: 30px 0 0 30px;
//   position:relative;
//   z-index:9
// `;
const Timer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 0.6em 0 0;
  align-items: flex-start;
  flex-direction: column;
  & span {
    font-size: 14px;
  }
`;

// const BackgroundImg = styled.div`
//   background: url(./images/background.jpg);
//   background-repeat: no-repeat;
//   background-size: cover;
//   max-width: 550px;
//   width: 40%;
//   position:relative;
//   &:before{
//     content:"";
//     position:absolute;
//     left:0;
//     right:0;
//     bottom:0;
//     top:0;
//     background-color:#00000060
//   }
// `;

// const Link = styled.button`
//   font-size: 14px;
//   line-height: 35px;
//   color: black;
//   text-align: end;
//   font-weight: 400;
//   cursor: pointer;
// `;
 
const Innerwapper = styled.div`
  padding: 30px;
  width: 100%;
  max-width: 580px;
  box-sizing: border-box;
  max-height: 100%;
  background-color:#fff;
  border-radius:6px;
  box-shadow: 0 0 12px #eeebeb;
`;
const Form = styled.form`
  width: 100%;
  margin: 40px 0;
`;
const Heading = styled.h1`
  font-size: 24px;
  line-height: 35px;
  color: #0a0b26;
  font-weight: 800;
  margin: 0;
`;
const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #505258;
  margin: 4px 0 0;
`;

const Btnwapper = styled.div`
  display: flex;
  gap: 15px;
  .resend-btn {
    background-color: transparent;
    color: #007955;
    border-color: transparent;
  }
`;
