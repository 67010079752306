import React from "react";
import styled from "styled-components";

export const MsDropdown = (props) => {
  const { onChangeText, touched, error } = props;
  return (
    <>
      <StyledWrapper>
        <SelectDropdown name="maritalstatus" id="maritalstatus" onChange={onChangeText}> 
            <option value="none">Il tuo stato civile</option>
            <option value="Married">Sposato/a</option>
            <option value="Unmarried">Celibe/Nubile</option>
            <option value="Separated">Separato/a</option>
            <option value="Cohabitant">Convivente</option>
            <option value="Widow">Vedovo/a</option>
        </SelectDropdown>
      </StyledWrapper>
      {touched && error && <Error className="error-name">{error}</Error>}
    </>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
`;

const SelectDropdown = styled.select`
    border: transparent;
    background: transparent;
    width: 100%;
    color: #202020;
    outline: none;
    appearance: auto;
    padding: 0 11px;

    &::-webkit-input-placeholder {
    color: rgba(192, 189, 204, 1);
    }

    &:-ms-input-placeholder {
    color: rgba(192, 189, 204, 1);
    }

    &::placeholder {
    color: rgba(192, 189, 204, 1);
    }
`
const Error = styled.div`
  color: red;
  font-size: 11px;
  margin-bottom: 0px;
  margin-top: -13px;
`;
