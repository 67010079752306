import { styled } from "styled-components";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
//import 'react-calendar/dist/Calendar.css';
//import { CircularProgressbar } from 'react-circular-progressbar';
import '../style/calendar.css';
import 'react-circular-progressbar/dist/styles.css';
import { getNews } from "../redux/services/news.service.js";
import { getFood } from "../redux/services/food.service.js";
import { getSurvey } from "../redux/services/survey.service.js";
import { Wrapper } from "../style/styled.js";
import { MobileHeader } from '../components/mobile/MobileHeader.js';
import { MobileFooter } from '../components/mobile/MobileFooter.js';
import { SectionHeader } from '../components/mobile/SectionHeader.js';

const MobileFoodDiary = (props) => {
  const { user } = props;
  const [value, onChange] = useState(new Date());
  const [displayFood, setDisplayFood] = useState();
  const [surveyList, setSurveyList] = useState();
  const [newsList, setNewsList] = useState();

  // if(isChild){
  //   if(user.age > 0 && user.age < 12){ limeSurveyUrl = "https://juniorchild.limesurvey.net/"}
  //   else if(user.age > 11 && user.age < 19){ limeSurveyUrl = "https://juniorchild.limesurvey.net/" }
  // }

  useEffect(()=>{
    value.setHours(0);
    value.setMinutes(0);
    value.setSeconds(0);
    value.setMilliseconds(0);
    if(user){
      const data = {
        user_id: user?._id,
        date: value
      } 
      getFood(data).then((response) => {
        setDisplayFood(response.data?.data)
      });
      getSurvey(data).then((response) => {
        setSurveyList(response.data?.data)
      });
      getNews(data).then((response) => {
        setNewsList(response.data?.data)
      });
    }
  }, [value, user?._id])
  return (
    <Wrapper style={{ flexDirection: "column" }}>
        <MobileHeader username={user?.name+" "+user?.surname} familycode={user?.familycode}/>
        <ContentWrapper>
            <SectionHeader>Diario Alimentare</SectionHeader>
        </ContentWrapper>
        <MobileFooter/>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.auth?.user,
    completedSurvey: state?.auth?.completedSurvey
  };
};
export default connect(mapStateToProps)(MobileFoodDiary);

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
`;