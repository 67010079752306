import { useFormik } from "formik";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Wrapper, LoginForm, Logo, LogoImage } from "../style/styled.js";
import { NavButton } from "../components/buttons/navbuttons.js";

const Notes = (props) => {

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      isChecked: "false",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setSubmitting(true);
      navigate("/login")
    },
  });

  const {
    handleSubmit,
    setSubmitting,
  } = formik;

  return (
    <Wrapper>
      <LoginForm>
        <LogoImage style={{ marginBottom: "10vh" }}>
          <Logo src={"/images/logo.png"} />
        </LogoImage>
        <Innerwapper>
          {/* <Heading>Parental consent</Heading> */}
          <Form onSubmit={handleSubmit}>
            <Text>Hai inviato correttamente la richiesta di partecipazione al tuo partner. aspetta la conferma per procedere alla compilazione dei questionari</Text>
            <ButtonWrapper>
              <NavButton
                name="Login"
                type="submit"
                className="form-btn"
                style={{ height:"57px", marginTop: 10 }}
              />
            </ButtonWrapper>
          </Form>
          <ToastContainer/>
        </Innerwapper>
      </LoginForm>
    </Wrapper>
  );
};

export default Notes;
  
const Innerwapper = styled.div`
  padding: 0 3vw 3vh 3vw;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  max-height: 100%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 3px 3px 0px #000;
  overflow: auto;
  margin-bottom: 5vh;

  @media only screen and (max-width: 1024px) {
    box-shadow: 0 0px 0px 0px #000;
    margin-bottom: 0vh;
  }
`;
const Form = styled.form`
  width: 100%;
  margin: 30px 0 0px;
  display: inline-block;
  > label {
    margin-bottom: 5px;
  }
  .form-btn {
    margin-top: 20px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 90%;
  justify-content: center;
  margin-inline: 3.8vw;
`;
const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #505258;
  text-align: center;
`;