import styled from "styled-components";

export const NavButton = (props) => {
  const { disabled, onPress, type } = props;
  return (
    <StyledNavButton
      onClick={onPress}
      disabled={disabled}
      {...props}
      type={type}
    >
      {props.name}
    </StyledNavButton>
  );
};

const StyledNavButton = styled.button`
  height: 44px;
  min-width: 140px;
  padding: 0 30px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #111111;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #02549b;
  box-sizing: border-box;
  text-transform: capitalise;
  cursor: pointer;
  z-index: 15;
  transition: all 0.1s ease-out;
  &:hover {
    background: #f0f1f5;
    transform: scale(1, 1);
  }

  @media only screen and (max-width:1024px){
    width: 100%;
  }
`;
