import styled from "styled-components";
import { Input } from "../components/inputs/input.js";
import { Button } from "../components/buttons/button.js";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { LoginSchema } from "../validations/loginValidation.js";
import { loginAction } from "../redux/actions/index.js";
import { InputLabels } from "../components/inputs/label.js";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { LoginForm, Link, Logo, LogoImage, Heading } from "../style/styled.js";
import { NavButton } from "../components/buttons/navbuttons.js";
import swal from "sweetalert";

const options = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: false,
  draggable: false,
};

const Login = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      //toast("Logging in...", options);
      swal({
        text: "Logging in...",
        timer: 5000
      });
      dispatch(loginAction(values, navigate))
        .then((res) => {
          setSubmitting(false)
        })
        .catch(() => setSubmitting(false))
    },
  });
  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    setSubmitting,
    isSubmitting
  } = formik;

  return (
    <Wrapper>
      <LoginForm>
        <LogoImage>
          <Logo src={"/images/logo.png"} />
        </LogoImage>
        <Innerwapper>
          <Heading>Log In</Heading>
          <Form onSubmit={handleSubmit}>
            <LeftSide>
              <Inputwapper>
                <InputLabels labelname="E-mail" />
                <Input
                  name="email"
                  type="text"
                  placeholder="E-mail"
                  value={values.email}
                  error={errors.email}
                  touched={touched.email}
                  onChangeText={(e) => setFieldValue("email", e.target.value)}
                />
              </Inputwapper>
              <Inputwapper>
                <InputLabels labelname="Password" />
                <Input
                  name="password"
                  type="password"
                  placeholder="Password"
                  touched={touched.password}
                  value={values.password}
                  error={errors.password}
                  onChangeText={(e) => setFieldValue("password", e.target.value)}
                />
              </Inputwapper>
            </LeftSide>
            <RightSide>
              <ButtonWrapper>
                <NavButton
                  onClick={() => navigate("/signup")}
                  name="Registrati"
                  style={{ height: '57px'  }} />
              </ButtonWrapper>
              <ButtonWrapper>
                <Button
                  disabled={isSubmitting}
                  name="Accedi"
                  type="submit"
                  style={{ marginTop: 15, height: '57px' }} />
              </ButtonWrapper>
            </RightSide>
          </Form>
            <Link
              onClick={() => {
                navigate("/forgot-password")
              }}
            >Ho dimenticato la password</Link>
          <ToastContainer/>
        </Innerwapper>
        <ButtonWrapper>
          <Button
            disabled={isSubmitting}
            name="Visita il sito del Progetto"
            onClick={() => window.location.href = 'https://studioicaro.neuromed.it/'}
            style={{ height: '57px' , backgroundColor: "#8db152", border: "1px solid #8db152" }} />
        </ButtonWrapper>
      </LoginForm>
    </Wrapper>
  );
};

export default Login;

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
`;

const Innerwapper = styled.div`
  padding: 30px;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  max-height: 100%;
  background-color:#fff;
  border-radius:15px;
  box-shadow: 0 3px 3px 0px #0000002b;
  max-height:90vh;
  overflow:auto;
  margin-bottom: 10vh;

  @media only screen and (max-width:1024px){
    box-shadow: 0 0px 0px 0px #000;
  }
`;
const Form = styled.form`
  width: 100%;
  margin: 30px 0 40px;
  display: flex;
  > label {
    margin-bottom: 5px;
  }

  @media only screen and (max-width:1024px){
    flex-direction: column;
    align-items: center;
  }
`;

const ButtonWrapper = styled.div`
  gap: 26px;
  display: flex;
  overflow: hidden;
  @media only screen and (max-width:1024px){
    width: 100%;
  }
`;

const Inputwapper = styled.div`
  position:relative;
  display: inline-block;
  border: 1px solid rgba(207, 211, 212, 1);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;
  .error-name{
    position:absolute;
    top:100%;
    padding-left: 15px;
  }
`

const LeftSide = styled.div`
  &:before{
    content:"";
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    background-color:#fff
  }
  @media only screen and (max-width:1024px){
    width: 90%;
  }

  position:fixed;
  top:0px;
  left:0px;
  bottom:0;
  right:0;
  padding: 0% 2% 0% 2%;
  position:relative;
  width: 46%;
`;

const RightSide = styled.div`
  &:before{
    content:"";
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
  }
  @media only screen and (max-width:1024px){
    top:0px;
    left:0px;
    bottom:0;
    right:0;
    padding: 3% 2%;
    position:relative;
    width: 90%;
  }

  top:0px;
  left:0px;
  bottom:0;
  right:0;
  padding: 0% 2% 0% 2%;
  position:relative;
  width: 46%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;