import React from "react";
import { MdOutlineClose } from "react-icons/md/index.esm.js";
import Modal from "react-modal";
import "../timeline/foodModel.css";

function ChildEditModal(props) {
  const { showModel, toggleModel, childSurvey } = props;

  const dataList = childSurvey?.map((item, index) => {
    return (
      <tr key={index}>
        <td>{item.sid}</td>
        <td>{item.surveyls_title}</td>
        <td>{item.complete ? "Si" : "No"}</td>
      </tr>
    );
  });

  return (
    <Modal isOpen={showModel} style={modelStyles} ariaHideApp={false}>
      <div className="model_head">
        <button onClick={() => toggleModel()}>
          <MdOutlineClose />
        </button>
      </div>
      <div className="upload_model_wrap">
        <div>{props?.selectedChild?.childname}</div>
        <div>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>ID Questionario</th>
                <th>Nome Questionario</th>
                <th>Completamento</th>
              </tr>
            </thead>
            <tbody style={{ height: "10vh", overflow: "auto" }}>
              {childSurvey && dataList}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
}

// const mapStateToProps = (state) => {
//   console.log(state?.childReducer)
//   return {
//     childSurvey: state?.childReducer?.currentEditOrView,
//   };
// };
export default ChildEditModal;

const modelStyles = {
  content: {
    top: "50%",
    zIndex: "100",
    left: "50%",
    right: "auto",
    padding: 0,
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
