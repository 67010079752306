import { styled } from "styled-components";
import { InputLabels } from "./inputs/label.js";
import { Input } from "./inputs/input.js";
import { Button } from "./buttons/button.js";

function Repeater(props) {
  //const [childrenList, setChildrenList] = useState([{ childname: "" }]);
  const { childrenList, setChildrenList } = props

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...childrenList];
    list[index][name] = value;
    setChildrenList(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...childrenList];
    list.splice(index, 1);
    setChildrenList(list);
  };

  const handleServiceAdd = () => {
    setChildrenList([...childrenList, { childname: "", surname: "", dobirth: "" }]);
  };

  return (
      <div className="form-field">
        {childrenList.map((singleService, index) => (
          <div key={index} className="services">
            <InlineBlock>
              <Inputwapper>
                  <InputLabels labelname={`Nome figlia/o`} />
                  <Input
                      name="childname"
                      type="text"
                      placeholder="nome del figlia/o"
                      //touched={touched.password}
                      value={singleService.childname}
                      //error={errors.password}
                      onChangeText={(e) => handleServiceChange(e, index)}
                  />
              </Inputwapper>
              <Inputwapper>
                  <InputLabels labelname={`Cognome figlia/o`} />
                  <Input
                      name="surname"
                      type="text"
                      placeholder="figlia/o Cognome"
                      //touched={touched.password}
                      value={singleService.surname}
                      //error={errors.password}
                      onChangeText={(e) => handleServiceChange(e, index)}
                  />
              </Inputwapper>
              <Inputwapper>
                  <InputLabels labelname={`data di nascita`} />
                  <Input
                      name="dobirth"
                      type="date"
                      placeholder="YYYY-MM-DD"
                      //touched={touched.password}
                      value={singleService.dobirth}
                      //error={errors.password}
                      onChangeText={(e) => {handleServiceChange(e, index)}}
                  />
              </Inputwapper>
              {index !== childrenList.length-1 && (
                    <Button
                        name="Rimuovi"
                        type='button'
                        className="form-btn"
                        style={{ height: "57px", marginTop: 0, background: "#ff0000", marginLeft: "30px" }}
                        onClick={() => handleServiceRemove(index)}
                    />
                )}
                {childrenList.length - 1 === index && childrenList.length < 6 && (
                    <Button
                        name="Aggiungi un figlia/o"
                        type='button'
                        className="form-btn"
                        style={{ height: "57px", marginTop: 0, marginLeft: "30px" }}
                        onClick={(e)=>{ handleServiceAdd() }}
                    />
                )}
            </InlineBlock>
          </div>
        ))}
        {/* <div className="output">
        {childrenList &&
          childrenList.map((singleService, index) => (
            <ul key={index}>
              {singleService.childname && <li>{singleService.childname}</li>}
            </ul>
          ))}
      </div> */}
      </div>
  );
}

export default Repeater;

const Inputwapper = styled.div`
  position: relative;
  display: inline-block;
  border: 1px solid rgba(207, 211, 212, 1);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;
  .error-name{
    position:absolute;
    top:100%;
    margin-top: -16px;
  }
`
const InlineBlock = styled.div`
  @media only screen and (max-width: 1024px) {
    display: flex;
  }
  display: flex;
  width: 100%;
  flex-direction: row;
`;