import { useFormik } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { spouseSelectChild } from "../redux/services/index.js";
import { Button } from "../components/buttons/button.js";
import { InputLabels } from "../components/inputs/label.js";
import { SelectChildSchema } from "../validations/signupValidation.js";
import { Checkbox } from "../components/inputs/checkboxes.js";
import { getChildOfParent } from "../redux/services/login.service.js";
import { Wrapper, LoginForm, Logo, LogoImage, Heading } from "../style/styled.js";
import swal from "sweetalert";

const SpouseChildSelect = (props) => {

  const [children, setChildren] = useState([])
  const [queryParameters] = useSearchParams();
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      isChecked: "false",
      selectedChild:[],
    },
    enableReinitialize: true,
    //validationSchema: SelectChildSchema,
    onSubmit: (values) => {
      values.token = location?.state?.token;
      //setSubmitting(true);
      spouseSelectChild(values)
        .then((response) => {
          //toast.success(response?.data?.message);
          //toast.success("Bambini selezionati per domande e risposte inviate al coniuge.");
          swal({
            text: "Bambini selezionati per domande e risposte inviate al coniuge.",
            timer: 5000
          });
          setSubmitting(false);
        })
        .catch((error) => {
          //toast.error(error?.response?.data?.message);
          swal({
            text: error?.response?.data?.message,
          });
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    const data = { token: location?.state?.token }
    getChildOfParent(data).then((response) => {
      setChildren(response?.data?.child)
    });
  }, []);

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    setSubmitting,
  } = formik;

  return (
    <Wrapper>
      <LoginForm>
        <LogoImage style={{ marginBottom: "0vh" }}>
          <Logo src={"/images/logo.png"} />
        </LogoImage>
        <Innerwapper>
          <Heading>Seleziona i figli che desideri far partecipare ai questionari</Heading>
          <Form onSubmit={handleSubmit}>
          <ChildCheckBoxes>
              { 
                children.length && children.map((item, index)=>{
                  return(
                    <Inputwapper key={index}>
                      <Checkbox name="selectedChild" onChange={formik.handleChange} value={item.childname} label={item.childname}/>
                    </Inputwapper>
                  )
                })
              }
            </ChildCheckBoxes>
            {/* <TCCheckbox className="team-row">
              <InputCheckbox
                name="isChecked"
                type="checkbox"
                defaultChecked={false}
                value={values.isChecked}
                error={errors.isChecked}
                touched={touched.isChecked?.toString()}
                onChange={() => {
                  setFieldValue("isChecked", values.isChecked === "false" ? "true": "false");
                }}
              />
              <CheckboxLabel htmlFor="conditions">
                Accettando la richiesta, do il permesso ai miei figli di rispondere ai quesstionari del programma NEWORKERS
              </CheckboxLabel>

              {values.isChecked && touched.isChecked && <Error className="error-name" style={{ marginTop: "-7px" }}>{errors.isChecked}</Error>}
            </TCCheckbox> */}

            <Button
              name="INVIA CONSENSO"
              type="submit"
              className="form-btn"
              style={{ marginTop: 10 }}
            // disabled={isSubmitting}
            />

          </Form>
          <ToastContainer/>
        </Innerwapper>
      </LoginForm>
    </Wrapper>
  );
};

export default SpouseChildSelect;
  
const Innerwapper = styled.div`
  padding: 0 3vw 3vh 3vw;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  max-height: 100%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 3px 3px 0px #000;
  overflow: auto;
  margin-bottom: 5vh;

  @media only screen and (max-width:1024px){
    box-shadow: 0 0px 0px 0px #000;
    margin-bottom: 0vh;
  }
`;
const Form = styled.form`
  width: 100%;
  margin: 30px 0 0px;
  display: inline-block;
  > label {
    margin-bottom: 5px;
  }
  .form-btn {
    margin-top: 20px;
  }
`;
const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #505258;
  margin: 4px 0 0;
`;
const Error = styled.div`
  color: red;
  font-size: 11px;
  margin-bottom: 0px;
  margin-top: -15px;
`;

const Inputwapper = styled.div`
  position: relative;
  display: inline-block;
  border: 1px solid rgba(207, 211, 212, 1);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;
  .error-name {
    position: absolute;
    top: 100%;
    padding-left: 15px;
  }
`
const InputCheckbox = styled.input`
  cursor: pointer;
  height: 16px;
  width: 16px;
`;
const TCCheckbox = styled.label`
  display: flex;
  gap: 10px;
  margin: 20px 0;
  align-items: center;
  position:relative;
  .error-name{
    position:absolute;
    top:100%;
    margin-top: -10px;
    padding-left: 15px;
  }
  &.team-row {
    padding-bottom: 10px;
  }
`;
const CheckboxLabel = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #7a86a1;
`;
const Anchor = styled.a`
  display: inline-block;
  margin: 0 0 0 5px;
  color:#007955;
  &:hover{
    color:#007955;
    text-decoration:none
  }
`;
const ChildCheckBoxes = styled.div`
  width: 100%;
`