import React from "react";
import styled from "styled-components";

const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 25px;
  height: 25px;
  background-clip: content-box;
  border: 2px solid #5e6366;
  border-radius: 5px;
  background-color: transparent;
  box-sizing: border-box;

  &:checked {
    background-color: #02549b;
    background-size: 100%;
    background-position: center center;
    border-radius: 5px;
    border: 2px solid #02549b;
  }
  &:focus {
    outline: none !important;
  }
`;
const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  color: #8c92a0;
  font-size: 18px;
  margin: 11px 0;
  padding: 0 15px;
  justify-content: space-between;
`;

export const Checkbox = (props) => {
  const { name, value, onChange, touched, error } = props;
  return (
    <>
      <StyledLabel>
        <span> {props.label}</span>
        <StyledCheckbox
          name={name}
          type="checkbox"
          onChange={onChange}
          value={value}
        />
      </StyledLabel>
      {touched && error && <div style={{ color: "red" }}>{error}</div>}
    </>
  );
};
