import { toast } from "react-toastify";
import {
  loginService,
  getChildInfo,
  getCompletedSurvey,
  childRegistarion
} from "../services/index.js";
import HandleAutoLogout from "../../utils/handleAutoLogout.js";
import { apiEffectError, apiEffectSuccess } from "../../utils/errorHandler.js";
import * as constant from "../constant/index.js";
import swal from "sweetalert";


export const FACEBOOK_LOGIN_SUCCESS = "FACEBOOK_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";

function detectMob() {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

export const loginAction = (args, navigate) => {
  return async (dispatch) => {
    dispatch({ type: constant.LOGIN_REQUEST });
    try {
      const res = await loginService(args);
      const data = res.data.res;
      if (data.user.role === "user" && data.token) {
        localStorage.setItem("access_token", data.token);
        apiEffectSuccess(res.data);
        dispatch({
          type: constant.LOGIN_SUCCESS,
          payload: data,
        });
        // new HandleAutoLogout({
        //   time: 10 * 60,
        //   onTimeOut: () => {
        //     localStorage.removeItem("access_token");
        //     console.log("Log out trigger");
        //     dispatch({ type: constant.CLEAR_STORE });
        //     //history?.push("/");
        //   },
        // });
        const comSurvey = await getCompletedSurvey(data?.user?._id);
        dispatch({
          type: constant.COMPLETED_SURVEY,
          payload: comSurvey?.data?.data,
        });
        const mb = detectMob()
        if(mb){ navigate("/home")}
        else{navigate("/")};
        return;
      } else {
        //toast.error("Please check you email id to verify your account.");
        swal({
          text: "Please check you email id to verify your account."
        });
        return;
      }
    } catch (error) {
      apiEffectError(error?.response?.data);
      return;
    }
  };
};

export const mutateUser = (data) => {
  return async (dispatch) => {
    dispatch({ type: constant.VERIFY_TOKEN_REQUEST });
    try {
      dispatch({
        type: constant.VERIFY_TOKEN_SUCCESS,
        payload: data,
      });
      const comSurvey = await getCompletedSurvey(data?.user?._id);
      dispatch({
        type: constant.COMPLETED_SURVEY,
        payload: comSurvey?.data?.data,
      });
      return data;
    } catch (error) {}
  };
};

export const handleEditOrView = (payload, navigate) => {
  return async (dispatch) => {
    return await getChildInfo(payload)
      .then((response)=>{
        dispatch({
          type: constant.MANAGE_CHILD_EDIT,
          currentEditOrView: response?.data?.child,
        });
        navigate('/child-edit')
      })
      .catch((error) => {
        //toast.error(error?.response?.data?.message);
        swal({
          text: error?.response?.data?.message,
        });
      });
  };
};

export const handleEditOrViewMobile = (payload, navigate) => {
  return async (dispatch) => {
    return await getChildInfo(payload)
      .then((response)=>{
        dispatch({
          type: constant.MANAGE_CHILD_EDIT,
          currentEditOrView: response?.data?.child,
        });
        navigate('/m-child-edit')
      })
      .catch((error) => {
        //toast.error(error?.response?.data?.message);
        swal({
          text: error?.response?.data?.message,
        });
      });
  };
};

export const childRegAction = (payload, setSuccessMsg, errors, setSubmitting) => {
  return async (dispatch) => {
    try{
      const response = await childRegistarion(payload);
      setSuccessMsg(response?.data?.message);
      setSubmitting(false);
      swal({
        text: response?.data?.message,
        timer: 5000
      });
      dispatch({
        type: constant.MANAGE_CHILD_REG,
        childdata: payload,
        registerData: response?.data?.data
      });
    }
    catch(error){
      console.log(error)
      errors.username = error?.response?.data?.message
      //toast.error(error?.response?.data?.message);
      if(error?.response?.data?.message){
        swal({
          text: error?.response?.data?.message,
        });
      }
      else{
        swal({
          text: "Registrazione Figlio/a avvenuta con successo",
          timer: 5000
        });
      }
      setSubmitting(false);
    };
  };
}

// export const facebookLogin = (args) => {
//   return async (dispatch) => {
//     dispatch({ type: LOGIN_REQUEST });
//     try {
//       const res = await facebookLoginService(args);
//       const { data } = res.data;
//       if (data?.user?.role === "user" && data?.token) {
//         localStorage.setItem("access_token", data.token);
//         apiEffectSuccess(res.data);
//         dispatch({
//           type: LOGIN_SUCCESS,
//           payload: data,
//         });
//         new HandleAutoLogout(10 * 60, () => {
//           localStorage.removeItem("access_token");
//           dispatch({ type: CLEAR_STORE });
//           history?.push("/");
//         });
//         history.push("ar-listing");
//       }
//     } catch (error) {
//       apiEffectError(error?.response?.data);
//     }
//   };
// };

// export const googleLogin = (args) => {
//   return async (dispatch) => {
//     dispatch({ type: LOGIN_REQUEST });
//     try {
//       const res = await googleLoginService(args);
//       const { data } = res.data;
//       if (data?.user?.role === "user" && data?.token) {
//         localStorage.setItem("access_token", data.token);
//         apiEffectSuccess(res.data);
//         dispatch({
//           type: LOGIN_SUCCESS,
//           payload: data,
//         });
//         new HandleAutoLogout(10 * 60, () => {
//           localStorage.removeItem("access_token");
//           dispatch({ type: CLEAR_STORE });
//           history?.push("/");
//         });
//         history.push("ar-listing");
//       }
//     } catch (error) {
//       apiEffectError(error?.response?.data);
//     }
//   };
// };
