import _ from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import OtpInput from "react-otp-input";
import styled from "styled-components";
import Countdown from "react-countdown";
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { Input } from "../components/inputs/input.js";
import { Button } from "../components/buttons/button.js";
import { InputLabels } from "../components/inputs/label.js";
import { reSendOtpToResetPasswordOnMail, resetPassword } from "../redux/services/index.js";
import { Wrapper, LoginForm } from "../style/styled.js";
import swal from "sweetalert";
// import { BsFillArrowLeftCircleFill } from "react-icons/bs";

const resetPasswordValidation = Yup.object().shape({
  new_password: Yup.string()
    .min(8, "Min 8 and max 15 characters are allowed.")
    .max(15, "Min 8 and max 15 characters are allowed.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must contain a capital, small and special character and a number."
    )
    .typeError("One special character is required.")
    .required("This field is required."),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Password must match.")
    .min(8, "Min 8 and max 15 characters are allowed.")
    .max(15, "Min 8 and max 15 characters are allowed.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must contain a capital, small and special character and a number."
    )
    .typeError("One special character is required.")
    .required("This field is required."),
});
const Resetpassword = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [dateValue, setDateValue] = useState(Date.now());
  const [resendValue, setResendValue] = useState(true)
  let myInput = null;
  useEffect(() => {
    if (location?.state.email) {
      window.sessionStorage.setItem("emailer", location?.state.email);
    }
    setEmail(window.sessionStorage.getItem("emailer"));
    setOtp(location?.otp);
  }, []);
  const formik = useFormik({
    initialValues: {
      otp: otp,
      email: email,
      new_password: "",
      confirm_password: "",
    },
    enableReinitialize: true,
    validationSchema: resetPasswordValidation,
    onSubmit: (values) => {
      let data = { ...values };
      resetPassword(data)
        .then((res) => {
          swal({
            text: res?.data?.message,
            timer: 5000
          });
          //toast.success(res?.data?.message);
          navigate("/");
        })
        .catch((error) => {
          //toast.error(error?.response?.data?.message);
          swal({
            text: error?.response?.data?.message,
          });
        });
    },
  });
  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    setFieldTouched,
    handleBlur,
  } = formik;
  const handleResendOtp = () => {
    const data = {
      email: email,
      otp: "FORGET_PASSWORD",
    };
    myInput.start();
    setResendValue(false)
    reSendOtpToResetPasswordOnMail(data)
      .then((res) => {
        if (res === undefined) {
          //toast.error("Please try again after some time.");
          swal({
            text: "Please try again after some time."
          });
          return;
        }
        setResendValue(true)
        window.location.reload();
        // toast.success(res && res.data && res.data.message)
      })
      .catch((error) => {
        //toast.error(error?.response?.data?.message);
        swal({
          text: error?.response?.data?.message,
        });
        setResendValue(false)
      });
  };
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (<span id="change">Resend OTP</span>);
    } else {
      if (seconds < 10) {
        seconds = "0" + String(seconds);
      }
      if (minutes < 10) {
        minutes = "0" + String(minutes);
      }
      return (
        <span id="change">
          Time: {minutes}:{seconds}
        </span>
      );
    }
  };
  const onComplete = () => {
    setResendValue(false)
  };
  const customStyleForOtp = {
    border: "1px solid #3E434D",
    background: "#fff",
    height: "50px",
    width: "50px",
    color: "rgb(35,121,85)",
    outline: "none",
    padding: "0px",
    margin: "0  10px 0 0",
    boxSizing: "border-box",
    borderColor: "#eaecf5",
    borderRadius: "5px",
    fontSize: "18px",
  };
  return (
    <Wrapper>
      {/* <BackgroundImg>
        <Logo src={"/images/logo.svg"} />
      </BackgroundImg> */}
      <LoginForm>
        <Innerwapper>
          {/* <Heading><Backbtn onClick={() => navigate.push("/")}>
            <BsFillArrowLeftCircleFill size={23} /></Backbtn>Reset Password</Heading> */}
          <Form onSubmit={handleSubmit}>
            <InputLabels labelname="OTP" />
            <Otpwapper>
              {/* <OtpInput
                type="text"
                value={values.otp}
                onChange={(otp) => {
                  setFieldValue("otp", otp);
                }}
                numInputs={6}
                inputStyle={customStyleForOtp}
              /> */}
              <OtpInput
                value={values.otp}
                onChange={(otp) => {
                  setFieldValue("otp", otp);
                }}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={customStyleForOtp}
              />
            </Otpwapper>
            <Inputwapper>
              <InputLabels labelname="New Password" />
              <Input
                type="password"
                placeholder="Password"
                name="new_password"
                touched={touched.new_password}
                value={values.new_password}
                disabled={!values.otp}
                error={errors.new_password}
                onBlur={handleBlur}
                onChangeText={(e) => {
                  setFieldValue("new_password", e.target.value);
                  setFieldTouched("new_password", true);
                }}
              />
            </Inputwapper>
            <Inputwapper>
              <InputLabels labelname="Confirm New Password" />
              <Input
                type="password"
                placeholder="Password"
                name="confirm_password"
                touched={touched.confirm_password}
                value={values.confirm_password}
                error={errors.confirm_password}
                disabled={!values.otp}
                onBlur={handleBlur}
                onChangeText={(e) => {
                  setFieldValue("confirm_password", e.target.value);
                  setFieldTouched("confirm_password", true);
                }}
              />
            </Inputwapper>
            {/* <Backbtn onClick={() => navigate.push("/")}>Back</Backbtn> */}
            <Button
              name="Reset"
              type="submit"
              style={{
                marginTop: 10,
                float: "right",
                background: !_.isEmpty(errors) ? "#007955a3" : "#007955",
              }}
            />
            {/* <Button
            name="Resend OTP"
            disabled={resendValue}
            type="button"
            style={{
              opacity: resendValue ? "0.5" : "1",
            }}
            className="resend-btn"
            onClick={() => handleResendOtp()}
          /> */}
            <button
              name="Resend OTP"
              disabled={resendValue}
              type="button"
              style={{
                opacity: resendValue ? "0.5" : "1",
              }}
              className="resend-btn"
              onClick={() => handleResendOtp()}
            >
              <Countdown
                ref={(ref) => (myInput = ref)}
                date={dateValue + 3 * 60 * 1000}
                renderer={renderer}
                onComplete={() => {
                  onComplete();
                }} />
            </button>
            {/* <div>
            <Countdown
              ref={(ref) => (myInput = ref)}
              date={dateValue + 5000}//3 * 60 * 1000}
              renderer={renderer}
              onComplete={() => {
                onComplete();
              }}
            />
          </div> */}
          </Form>
          <ToastContainer/>
        </Innerwapper>
      </LoginForm>
    </Wrapper>
  );
};
export default Resetpassword;

const Innerwapper = styled.div`
  padding: 30px;
  width: 100%;
  max-width: 580px;
  box-sizing: border-box;
  background-color:#fff;
  border-radius:6px;
  box-shadow: 0 0 12px #eeebeb;
  max-height:90vh;
  overflow:auto
`;
const Form = styled.form`
  width: 100%;
  margin-top: 40px;
  .resend-btn{
    min-width: 140px;
    height: 46px;
    padding: 0 30px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background: #007955;
    border: 1px solid #007955;
    box-sizing: border-box;
    text-transform: capitalise;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
  }
`;
const Heading = styled.h1`
  font-size: 30px;
  line-height: 35px;
  color: #0a0b26;
  font-weight: 800;
  margin: 0;
  position:relative;
  padding-left:35px
`;
const Otpwapper = styled.div`
  margin-bottom: 15px;
`;
const Backbtn = styled.span`
  color: #007955;
  padding: 0px;
  height: 46px;
  cursor: pointer;
  position:absolute;
  left:0;
  top:-2px;
`;
const Inputwapper = styled.div`
  position:relative;
  display: inline-block;
  width: 100%;
  .error-name{
    position:absolute;
    top:100%;
    padding-left: 15px;
  }
`