import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  // email: Yup.string()
  //   .email("Si prega di inserire un indirizzo email valido.")
  //   .required("Questo campo è obbligatorio."),
  password: Yup.string()
    .typeError("È richiesto un carattere speciale.")
    .required("Questo campo è obbligatorio."),
});
