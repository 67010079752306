import { useFormik } from "formik";
import { useState } from "react";
import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";
import { Input } from "../components/inputs/input.js";
import { Button } from "../components/buttons/button.js";
import Repeater from "../components/repeater.js";
import { addChildren } from "../redux/services/login.service.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InputLabels } from "../components/inputs/label.js";
import { LoginForm, Logo, LogoImage, Heading } from "../style/styled.js";
import { sendEmailtoSpouse } from "../redux/services/index.js";
import { spouseEmailSchema } from "../validations/signupValidation.js";
import swal from "sweetalert";

const options = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: false,
  draggable: false,
};

const ChildAdd = (props) => {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const token = queryParameters.get("user");
  
  const [childrenList, setChildrenList] = useState([{ childname: "", surname: "", dobirth: "" }]);
  const [isEmailSent, setIsEmailSent] = useState(false);


  const formik = useFormik({
    initialValues: {
      spousemail: "",
      spousesurname: "",
      isChecked: "false"
    },
    enableReinitialize: true,
    validationSchema: spouseEmailSchema,
    onSubmit: (values) => {
      //setSubmitting(true);
      const listlength = childrenList.length;
      if(listlength === 1 && childrenList[listlength-1].childname === ""){
        swal({
          text: "Devi aggiungere almeno un figlio/a",
          timer: 5000
        });
        return;
      }
      if(childrenList[listlength-1].childname === ""){
        childrenList.pop()
      }
      const data = { children: childrenList, spouse: values };
      swal({
        text: "Aggiunta del bambino...",
        timer: 5000
      });
      addChildren(data, token)
        .then((response) => {
          //toast.success(response?.data?.message);
          swal({
            text: response?.data?.message,
            timer: 5000
          });
          setSubmitting(false);
          navigate("/note")
        })
        .catch((error) => {
          swal({
            text: error?.response?.data?.message
          });
          setSubmitting(false);
        });
    },
  });
  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    setSubmitting,
    isSubmitting,
  } = formik;

  return (
    <Wrapper>
      <LoginForm>
        <LogoImage style={{ marginBottom: "5vh" }}>
          <Logo src={"/images/logo.png"} />
        </LogoImage>
        <Innerwapper>
          <Heading>Aggiungi i dettagli dei tuoi figli</Heading>
          <Form onSubmit={handleSubmit}>
            <Repeater
              childrenList={childrenList}
              setChildrenList={setChildrenList}
            />
            {values.isChecked !== "true" && <InlineBlock>
              <Inputwapper>
                <InputLabels labelname="Cognome del coniuge" />
                <Input
                  name="spousesurname"
                  type="text"
                  placeholder="Cognome del coniuge"
                  value={values.spousesurname}
                  error={errors.spousesurname}
                  touched={touched.spousesurname}
                  onChangeText={(e) =>
                    setFieldValue("spousesurname", e.target.value)
                  }
                />
              </Inputwapper>
            </InlineBlock>}
            {values.isChecked !== "true" && <InlineBlock>
              <Inputwapper>
                <InputLabels labelname="E-mail del coniuge" />
                <Input
                  name="spousemail"
                  type="text"
                  placeholder="E-mail del tuo coniuge"
                  value={values.spousemail}
                  error={errors.spousemail}
                  touched={touched.spousemail}
                  onChangeText={(e) =>
                    setFieldValue("spousemail", e.target.value)
                  }
                />
              </Inputwapper>
              {/* <Button
                name="Invia un invito"
                type="button"
                className="form-btn"
                style={{ height: "57px", marginTop: 0, marginLeft: "30px" }}
                onClick={(e) => {
                  spouseEmailReq();
                }}
              /> */}
            </InlineBlock>}
            <Checkbox className="team-row">
              <InputCheckbox
                name="isChecked"
                type="checkbox"
                defaultChecked={false}
                value={values.isChecked}
                error={errors.isChecked}
                touched={touched.isChecked?.toString()}
                onChange={() => {
                  setFieldValue("spousesurname", "")
                  setFieldValue("spousemail", "")
                  setFieldValue("isChecked", values.isChecked === "false" ? "true" : "false");
                }}
              />
                <CheckboxLabel htmlFor="conditions">
                  {" "}
                  Sono l'unico ad esercitare la potestà genitoriale.
                </CheckboxLabel>

              {values.isChecked && touched.isChecked && (
                <Error className="error-name" style={{ marginTop: "-7px" }}>
                  {errors.isChecked}
                </Error>
              )}
            </Checkbox>
            <ButtonWrapper>
              <Button
                //disabled={isSubmitting}
                name="Invia"
                type="submit"
                style={{ height: "57px", marginTop: 10 }}
              />
            </ButtonWrapper>
          </Form>
          <ToastContainer />
        </Innerwapper>
      </LoginForm>
    </Wrapper>
  );
};

export default ChildAdd;

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
`;

const Innerwapper = styled.div`
  padding: 0 3vw 3vh 3vw;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  max-height: 100%;
  background-color: #fff;
  border-radius: 15px;
  max-height: 90vh;
  overflow: auto;
  box-shadow: 0 3px 3px 0px #000000;
  max-height:90vh;
  margin-bottom: 10vh;

  @media only screen and (max-width:1024px){
    box-shadow: 0 0px 0px 0px #000;
  }
`;
const Form = styled.form`
  width: 100%;
  margin: 30px 0 40px;
  display: inline-block;
  > label {
    margin-bottom: 5px;
  }
`;
const ButtonWrapper = styled.div`
  gap: 26px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
`;
const Inputwapper = styled.div`
  position: relative;
  display: inline-block;
  border: 1px solid rgba(207, 211, 212, 1);
  border-radius: 8px;
  width: 600%;
  margin-bottom: 15px;
  .error-name {
    position: absolute;
    top: 100%;
    padding-left: 15px;
  }
`;

const InlineBlock = styled.div`
  @media only screen and (max-width: 1024px) {
    display: flex;
  }
  display: flex;
  width: 100%;
  flex-direction: row;
`;
const InputCheckbox = styled.input`
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin: 0;
`;
const Checkbox = styled.label`
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
  width: 90%;
  .error-name {
    position: absolute;
    top: 100%;
    margin-top: -10px;
    padding-left: 15px;
  }
  &.team-row {
    padding-bottom: 10px;
  }
`;

const CheckboxLabel = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #7a86a1;
`;
const Error = styled.div`
  color: red;
  font-size: 11px;
  margin-bottom: 0px;
  margin-top: -15px;
`;
