import { combineReducers } from "redux";
import { loginReducer } from "./login.reducer.js";
import { childReducer } from "./child.reducer.js";
//import { CLEAR_STORE } from "../actions/index.js";
// import { arReducer } from "./create-ar/create-ar.reducer";
// import { viewArReducer } from "./view-ar/view-ar.reducer";
// import { editArReducer } from "./edit-ar/edit-ar.reducer";
// import { transactionReducer } from "./transaction/transaction.reducer";
// import { profileReducer } from "./profile/profile.reducer";
// import arLibraries from "./ar-libraries/ar-libraries.reducer";
// import toolReducer from "./tool/tool.reducer";
const appReducer = combineReducers({
  auth: loginReducer,
  childReducer: childReducer
//   arReducer: arReducer,
//   transactionReducer: transactionReducer,
//   profileReducer: profileReducer,
//   editArReducer: editArReducer,
//   viewArReducer: viewArReducer,
//   arLibraries: arLibraries,
//   toolReducer: toolReducer
});

export default function rootReducer(state, action) {
  if (action.type === "CLEAR_STORE") {
    state = undefined;
  }
  return appReducer(state, action);
}
