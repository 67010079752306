import Calendar from 'react-calendar';
import { styled } from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { BiTrash, BiPencil, BiLineChart } from "react-icons/bi/index.esm.js";
//import 'react-calendar/dist/Calendar.css';
//import { CircularProgressbar } from 'react-circular-progressbar';
import '../style/calendar.css';
import FoodLogo from "../Fooddiary.png"
import { Logout } from "../components/Logout.js";
import NewsList from "../components/NewsList.js";
import 'react-circular-progressbar/dist/styles.css';
import { Button } from "../components/buttons/button.js";
import Timeline from "../components/timeline/Timeline.js";
import FoodModal from "../components/timeline/FoodModal.js";
import { getNews } from "../redux/services/news.service.js";
import { getFood } from "../redux/services/food.service.js";
import { getSurvey } from "../redux/services/survey.service.js";
import ChildRegModal from "../components/modal/ChildRegModal.js";
import ChildEditModal from "../components/modal/ChildEditModal.js";
import { handleEditOrView } from "../redux/actions/login.action.js";
import ProgressListing from "../components/table/MyProgressTable.js";
import { Wrapper, Logo, LogoImage, BackGroundImage } from "../style/styled.js";
import { getCompletedSurvey, childSurveyList } from "../redux/services/survey.service.js";
import { MobileHeader } from '../components/mobile/MobileHeader.js';
import { MobileFooter } from '../components/mobile/MobileFooter.js';
import { SectionHeader } from '../components/mobile/SectionHeader.js';

const MobileQuestionary = (props) => {
  const { user, completedSurvey } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isChild = (user?.age !== undefined)
  const [value, onChange] = useState(new Date());
  const [displayFood, setDisplayFood] = useState();
  const [surveyList, setSurveyList] = useState();
  const [newsList, setNewsList] = useState();
  let limeSurveyUrl = "https://neuromedspa.limesurvey.net/"

  // if(isChild){
  //   if(user.age > 0 && user.age < 12){ limeSurveyUrl = "https://juniorchild.limesurvey.net/"}
  //   else if(user.age > 11 && user.age < 19){ limeSurveyUrl = "https://juniorchild.limesurvey.net/" }
  // }

  const takeToSurvey = async (item) => {
    window.open(`${limeSurveyUrl}${item.sid}?token=${user?.email?.replace(/[^a-zA-Z0-9_ ]/g, "")}&lang=it`, "_blank");
  }

  useEffect(()=>{
    value.setHours(0);
    value.setMinutes(0);
    value.setSeconds(0);
    value.setMilliseconds(0);
    if(user){
      const data = {
        user_id: user?._id,
        date: value
      } 
      getSurvey(data).then((response) => {
        setSurveyList(response.data?.data)
      });
    }
  }, [value, user?._id])
  return (
    <Wrapper style={{ flexDirection: "column" }}>
        <MobileHeader username={user?.name+" "+user?.surname} familycode={user?.familycode}/>
        <ContentWrapper>
            <SectionHeader>Questionari</SectionHeader>
            <QuestionDiv>
            { surveyList && surveyList.map((item, index)=>{
                if(item.active === "Y")
                    return(
                        <SurveyDiv key={index} style={{padding: "0.1vh"}}>
                            <ChildName>{item.surveyls_title}</ChildName> 
                            {/* <a key={index} href={`${limeSurveyUrl}${item.sid}?token=${user?.email?.replace(/[^a-zA-Z0-9_ ]/g, "")}&lang=it`} target="_blank" rel="noopener noreferrer">visualizzazione</a> */}
                            <Button name="visualizzazione" style={{borderRadius: "10px", height: "36px", backgroundColor: "#408562", minWidth: "114px", padding: "0 5px", border: "1px solid #408562", maxWidth: "115px"}} onClick={()=>{ takeToSurvey(item)}}/>
                        </SurveyDiv>
                    )
                })
            }
            </QuestionDiv>
        </ContentWrapper>
        <MobileFooter/>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.auth?.user,
    completedSurvey: state?.auth?.completedSurvey
  };
};
export default connect(mapStateToProps)(MobileQuestionary);

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 60vh;
`;
const SurveyDiv = styled.div`
  //width: 90%;
  padding: 1vh;
  min-height: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ChildName = styled.div`
  min-width: 100px;
`;
const QuestionDiv = styled.div`
    height: 50vh;
    overflow: auto;
    padding: 0 2vw;
`;