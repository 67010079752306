import { axiosClient } from "../../utils/axios-client.js";

export const loginService = async (data) => {
  let args = {
    url: "/users/login",
    method: "post",
    data,
  };
  return axiosClient(args);
};

// export const facebookLoginService = async (data) => {
//   let args = {
//     url: "/users/login-with-facebook",
//     method: "post",
//     data,
//   };
//   return axiosClient(args);
// };

// export const googleLoginService = async (data) => {
//   let args = {
//     url: "/users/login-with-google",
//     method: "post",
//     data,
//   };
//   return axiosClient(args);
// };

export const verifyToken = async ({ token }) => {
  let args = {
    url: "/users/verify-token",
    method: "post",
    params: { token },
  };
  return axiosClient(args);
};

export const signUp = (payload) => {
  let args = {
    url: "/users/sign-up",
    method: "POST",
    data: payload,
  };

  return axiosClient(args);
};

export const addChildren = (payload, token) => {
  let args = {
    url: "/users/add-children",
    method: "POST",
    data: payload,
    headers: { token }
  };

  return axiosClient(args);
};

export const reSendOtpToResetPasswordOnMail = (payload) => {
  let args = {
    url: "/users/resend-otp",
    method: "POST",
    data: payload,
  };

  return axiosClient(args);
};

export const verifyAccount = (payload) => {
  let args = {
    url: `/users/verify-account?email=${payload.email}&otp=${payload.otp}`,
    method: "GET",
  };
  return axiosClient(args);
};

export const forgotPassword = (payload) => {
  let args = {
    url: "/users/forget-password",
    method: "POST",
    params: payload,
  };

  return axiosClient(args);
};

export const resetPassword = (payload) => {
  let args = {
    url: "/users/reset-password",
    method: "POST",
    params: payload,
  };

  return axiosClient(args);
};

export const sendEmailtoSpouse = (payload, token) => {
  let args = {
    url: "/users/spouse-req",
    method: "POST",
    data: payload,
    headers : { token }
  };

  return axiosClient(args);
};

export const sendWpMsgtoSpouse = (payload) => {
  let args = {
    url: "/users/spouse-wp-req",
    method: "POST",
    data: payload,
  };

  return axiosClient(args);
};

export const getChildOfParent = (payload) => {
  let args = {
    url: `/users/get-child`,
    method: "POST",
    data: payload,
  };

  return axiosClient(args);
};

export const spouseSignUp = (payload, token) => {
  let args = {
    url: `/users/spouse-reg`,
    method: "POST",
    data: payload,
    headers : { token }
  };

  return axiosClient(args);
};

export const spouseSelectChild = (payload, token) => {
  let args = {
    url: `/users/spouse-childsel`,
    method: "POST",
    data: payload,
    headers : { token }
  };

  return axiosClient(args);
};

export const spouseNoOne = (payload, token) => {
  let args = {
    url: `/users/spouse-noone`,
    method: "POST",
    data: payload,
    headers : { token }
  };

  return axiosClient(args);
};

export const verifySpouseRegistrationReq = (payload) => {
  let args = {
    url: `/users/verify-spouse-req`,
    method: "POST",
    data: payload
  };

  return axiosClient(args);
};

export const childRegistarion = (payload) => {
  let args = {
    url: `/users/child-reg`,
    method: "POST",
    data: payload
  };

  return axiosClient(args);
}

export const getChildInfo = (payload) => {
  let args = {
    url: `/users/get-child/${payload.userId}`,
    method: "GET",
  };

  return axiosClient(args);
};

export const updateChild = (payload) => {
  let args = {
    url: `/users/edit-child`,
    method: "POST",
    data: payload
  };

  return axiosClient(args);
};