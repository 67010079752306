import { useFormik } from "formik";
import { useEffect } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/buttons/button.js";
import { SpouseOptionSchema } from "../validations/signupValidation.js";
import {
  Wrapper,
  LoginForm,
  Logo,
  LogoImage,
  Heading,
} from "../style/styled.js";
import { verifySpouseRegistrationReq } from "../redux/services/login.service.js";

const SpouseOption = (props) => {
  const [queryParameters] = useSearchParams();
  const token = queryParameters.get("token");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      picked:""
    },
    enableReinitialize: true,
    validationSchema: SpouseOptionSchema,
    onSubmit: (values) => {
      navigate(`/${values.picked}`, { state: { token } });
    },
  });

  useEffect(() => {
    const data = { token }
    verifySpouseRegistrationReq(data).then((response) => {
        console.log(response)
    });
  }, []);

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    setSubmitting,
  } = formik;

  return (
    <Wrapper>
      <LoginForm>
        <LogoImage style={{ marginBottom: "10vh" }}>
          <Logo src={"/images/logo.png"} />
        </LogoImage>
        <Innerwapper>
          <Heading>INDICHI UNA DELLE SEGUENTI OPZIONI:  
(Scegliere solo una delle seguenti voci)</Heading>
          <Form onSubmit={handleSubmit}>
            <TCCheckbox className="team-row">
              <InputCheckbox
                name="picked"
                type="radio"
                value="spouse-signup"
                // error={errors.isChecked}
                // touched={touched.isChecked?.toString()}
                onChange={() => {
                    setFieldValue(
                      "picked",
                      values.picked === "a" ? "" : "spouse-signup"
                    );
                  }}
              />
              <CheckboxLabel htmlFor="conditions">
                {" "}Autorizzo la partecipazione di mio figlio/i allo studio ICARO e chiedo di partecipare attivamente allo studio in qualità di genitore
              </CheckboxLabel>

              {values.isChecked && touched.isChecked && (
                <Error className="error-name" style={{ marginTop: "-7px" }}>
                  {errors.isChecked}
                </Error>
              )}
            </TCCheckbox>
            <TCCheckbox className="team-row">
              <InputCheckbox
                name="picked"
                type="radio"
                value="spouse-selectchild"
                // error={errors.isChecked}
                // touched={touched.isChecked?.toString()}
                onChange={() => {
                  setFieldValue(
                    "picked",
                    values.picked === "a" ? "" : "spouse-selectchild"
                  );
                }}
              />
              <CheckboxLabel htmlFor="conditions">
                {" "}Autorizzo la partecipazione di mio figlio/i allo studio ICARO
              </CheckboxLabel>

              {values.isChecked && touched.isChecked && (
                <Error className="error-name" style={{ marginTop: "-7px" }}>
                  {errors.isChecked}
                </Error>
              )}
            </TCCheckbox>
            <TCCheckbox className="team-row">
              <InputCheckbox
                name="picked"
                type="radio"
                value="spouse-noone"
                error={errors.picked}
                // touched={touched.isChecked?.toString()}
                onChange={() => {
                  setFieldValue(
                    "picked",
                    values.picked === "a" ? "" : "spouse-noone"
                  );
                }}
              />
              <CheckboxLabel htmlFor="conditions">
                {" "}NON autorizzo la partecipazione di mio figlio/i allo studio ICARO
              </CheckboxLabel>

              {!values.picked  && (
                <Error className="error-name" style={{ marginTop: "-7px" }}>
                  {errors.picked}
                </Error>
              )}
            </TCCheckbox>
            <ButtonWrapper>
                <Button
                name="INVIA"
                type="submit"
                className="form-btn"
                style={{ marginTop: 10 }}
                // disabled={isSubmitting}
                />
            </ButtonWrapper>
          </Form>
          <ToastContainer />
        </Innerwapper>
      </LoginForm>
    </Wrapper>
  );
};

export default SpouseOption;

const Innerwapper = styled.div`
  padding: 0 3vw 3vh 3vw;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  max-height: 100%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 3px 3px 0px #000;
  overflow: auto;
  margin-bottom: 5vh;

  @media only screen and (max-width: 1024px) {
    box-shadow: 0 0px 0px 0px #000;
    margin-bottom: 0vh;
  }
`;
const Form = styled.form`
  width: 100%;
  margin: 30px 0 0px;
  display: inline-block;
  > label {
    margin-bottom: 5px;
  }
  .form-btn {
    margin-top: 20px;
  }
`;
const Error = styled.div`
  color: red;
  font-size: 11px;
  margin-bottom: 0px;
  margin-top: -15px;
`;

const InputCheckbox = styled.input`
  cursor: pointer;
  height: 16px;
  width: 16px;
`;
const TCCheckbox = styled.label`
  display: flex;
  gap: 10px;
  margin: 20px 0;
  align-items: center;
  position: relative;
  .error-name {
    position: absolute;
    top: 100%;
    margin-top: -10px;
    padding-left: 15px;
  }
  &.team-row {
    padding-bottom: 10px;
  }
`;
const CheckboxLabel = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #000;
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 90%;
  justify-content: center;
  margin-inline: 3.8vw;
`;
