import "./table.css";

const ProgressListing = ({completedSurvey}) => {
  const dataList = completedSurvey?.map((item, index) => {
    return (
      <tr key={index}>
        <td>{item.sid}</td>
        <td>{item.surveyTitle}</td>
        {/* <td>{completePer}%</td> */}
      </tr>
    );
  });

  return (
    <div className="table-responsive">
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <th>ID Questionario</th>
            <th>Questionario completato</th>
            {/* <th>Completamento</th> */}
          </tr>
        </thead>
        <tbody style={{height: "10vh", overflow:"auto"}}>{completedSurvey && dataList}</tbody>
      </table>
    </div>
  );
};

export default ProgressListing;
