import styled from "styled-components";
import { BsCalendar2Date } from "react-icons/bs/index.esm.js";
import { BsEnvelopeOpen } from "react-icons/bs/index.esm.js";
import { BsHouseDoor } from "react-icons/bs/index.esm.js";
import { BsBookHalf } from "react-icons/bs/index.esm.js";
import { BsCardChecklist } from "react-icons/bs/index.esm.js";
import { useNavigate } from "react-router-dom";


export const MobileFooter = (props) => {
  const navigate = useNavigate();
  return (
    <FooterDiv>
      <LogoImage style={{ marginBottom: "0vh" }}>
        <Logo src={"/images/logo.png"} />
      </LogoImage>
      <FooterNav>
        <BsHouseDoor color={"white"} size={24} onClick={()=>{navigate("/home")}}/>
        <BsCardChecklist color={"white"} size={24} onClick={()=>{navigate("/questionary")}}/>
        <BsEnvelopeOpen color={"white"} size={24} onClick={()=>{navigate("/news")}}/>
        <BsCalendar2Date color={"white"} size={24} onClick={()=>{navigate("/calender")}}/>
        <BsBookHalf color={"white"} size={24} onClick={()=>{navigate("/food-diary")}}/>
      </FooterNav>
    </FooterDiv>
  );
};

const FooterDiv = styled.div`
  width: 100%;
  height: 20vh;
  background: #fff;
  top: 80vh;
  position: absolute;
`;

const FooterNav = styled.div`
  border-radius: 40px;
  background: #7CBD4C;
  height: 8vh;
  display: flex;
    justify-content: space-around;
    align-items: center;
`;

const Logo = styled.img`
  width: 80%;
  height: auto;
  position:relative;
  z-index:1
`;

const LogoImage = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 12vh;
  position:relative;
  display: flex;
  justify-content: center;
  &:before{
    content:"";
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
  }
`;