import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../redux/reducers/index.js";

const middlewares = [thunk];

export const store = configureStore(
    {reducer: rootReducer}, 
    applyMiddleware(middlewares)
)