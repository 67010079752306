import React from "react";
import styled from "styled-components";
export const Input = (props) => {
  const { disabled, onChangeText, value, touched, error, onBlur } = props;
  return (
    <>
      <StyledWrapper>
        <TextInput
          name={props?.name}
          placeholder={props.placeholder}
          type={props.type}
          onChange={onChangeText}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          accept={props?.accept}
          autoComplete="new-password"
        />
      </StyledWrapper>
      {touched && error && <Error className="error-name">{error}</Error>}
    </>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  //border: 1px solid rgba(234, 236, 245, 1);
  //border-radius: 5px;
  box-sizing: border-box;
  //margin-bottom: 15px;
  overflow: hidden;
`;
const TextInput = styled.input`
  border: transparent;
  background: transparent;
  height: 32px;
  width: 100%;
  color: #202020;
  outline: none;
  appearance: none;
  padding: 0 15px;

  &::-webkit-input-placeholder {
    color: rgba(192, 189, 204, 1);
  }

  &:-ms-input-placeholder {
    color: rgba(192, 189, 204, 1);
  }

  &::placeholder {
    color: rgba(192, 189, 204, 1);
  }
`;
const Error = styled.div`
  color: red;
  font-size: 11px;
  margin-bottom: 0px;
  margin-top: -13px;
`;
