import styled from "styled-components"

export const SectionHeader = styled.div`
  background: #E31B57 ;
  color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(1.1 * (1.5vh + 1.1vw));
  margin-top: 5vh;
  width: 80vw;
`