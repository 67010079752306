import { axiosClient } from "../../utils/axios-client.js";

export const getNews = (payload) => {
  let args = {
    url: "/news/get-news",
    method: "GET",
  };

  return axiosClient(args);
};
