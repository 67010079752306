import { styled } from "styled-components";

export const Wrapper = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    overflow: hidden;
`;

export const LoginForm = styled.div`
    flex: 1;
    background: #fff;
    padding: 0px;
    // max-width: calc(100% - 550px);
    width: 80%;
    box-sizing: border-box;
    max-height: 100%;
    overflow: auto;
    display: flex ;
    align-items: center;
    justify-content: center;
    background-color:#fff;
    flex-direction: column;
`;

export const Link = styled.div`
  color: #5570f1;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  margin: -15px 0 0;
  text-align: center;
`;

export const Logo = styled.img`
  width: 100%;
  height: 18vh;
  position:relative;
  z-index:9
`;

export const BackGroundImage = styled.img`
  width: 100%;
  height: 100%;
  position:fixed;
  top: -50px;
  z-index:-9
`;

export const LogoImage = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 550px;
  width: 40%;
  position:relative;
  margin-bottom: 15vh;
  &:before{
    content:"";
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
  }

  @media only screen and (max-width:1024px){
    margin-bottom: 10vh;
    width: 100%;
  }
`;

export const Heading = styled.h1`
  font-size: 30px;
  line-height: 4vh;
  color: #0a0b26;
  font-weight: 400;
  margin: 0;
  text-align: center;
`;

  