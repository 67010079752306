import styled from "styled-components";
import { MdLogout } from "react-icons/md/index.esm.js";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CLEAR_STORE } from "../redux//constant/index.js";

export const Logout = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const UserLogout = () => {
        swal({
          title: "Sei sicuro di voler uscire?",
    
          buttons: ["No", "SÌ"],
        }).then((willDelete) => {
          if (willDelete) {
            localStorage.removeItem("access_token");
            dispatch({ type: CLEAR_STORE });
            navigate("/login");
          } else {
            return;
          }
        });
    };
    return (
        <LogoutDiv>
            <LogOut onClick={() => UserLogout()}>
                <MdLogout className="mr-10" size={24} /> Logout
            </LogOut>
        </LogoutDiv>
    );
};

const LogoutDiv = styled.div`
    position: fixed;
    top: 8vh;
    right: 3vw;
    width: 9%;
    height: 6vh;
    background: #02549b;
    color: #fff;
    border-radius: 10px;
`;

const LogOut = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;