import React, { useState } from "react";
import { useFormik } from "formik";
import { MdOutlineClose } from "react-icons/md/index.esm.js";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { InputLabels } from "../inputs/label.js";
import { Input } from "../inputs/input.js";
import styled from "styled-components";
import { Button } from "../buttons/button.js";
import "./foodModel.css"
import { addFood } from "../../redux/services/food.service.js";
export default function FoodModal(props) {
  const [modalData, setModalData] = useState({
    breakfast: "",
    lunch: "",
    dinner: "",
  });
  const { showModel, toggleModel, foodDate, userId } = props;
  const params = useParams();

  const formik = useFormik({
    initialValues: {
      breakfast: "",
      lunch: "",
      dinner:""
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setSubmitting(true);
      values.date = foodDate
      values.user_id = userId
      // dispatch(loginAction(values, navigate))
      //   .then((res) => {
      //     setSubmitting(false)
      //   })
      //   .catch(() => setSubmitting(false))
      addFood(values).then((res)=>{
        setSubmitting(false);
      })
    },
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    setSubmitting,
    isSubmitting
  } = formik;

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      // Check file size

      let reader = new FileReader();
      reader.onload = function (ev) {
        setModalData({
          name: e.target.files[0].name,
          uri: ev.target.result,
          fileData: e.target.files[0],
        });
        setTimeout(() => {
          onFileUpload({
            fileData: e.target.files[0],
          });
        }, 1000);
      };
      reader.readAsDataURL(e.target.files[0]);
      setTimeout(console.log(modalData), 1000);
    }
  };

  const onFileUpload = async ({ fileData }) => {
    const formData = new FormData();
    setModalData((prev) => ({ ...prev, submitting: true }));
  };

  return (
    <Modal isOpen={showModel} style={modelStyles} ariaHideApp={false}>
      <div className="model_head">
        <button onClick={() => toggleModel()}>
          <MdOutlineClose />
        </button>
      </div>
      <div className="upload_model_wrap">
        <div className="upload_inner">
          {/* {!modalData.submitting ? (
            <div className="upload_content">
              <h5>Upload the 3d Model you want to add augmented reality to</h5>
              <div style={{ height: "60px" }}></div>
              <span className="upload_image_icon">
                <BsCardImage size={100} />
              </span>
              <div style={{ height: "50px" }}></div>
              <label htmlFor={"marker_image"}>
                <input
                  draggable="true"
                  type="file"
                  onChange={(e) => handleChange(e)}
                  name="marker_image"
                  accept=".glb,.gltf"
                />
                <button>
                  <GiCloudUpload /> Upload
                </button>
              </label>

              <p>Supported formats: GLB</p>
              <p>Optimal file sizes: 1-20 MB</p>
              <div style={{ height: "30px" }}></div>
              <p>
                For best performance make sure that your 3d Modal has high
                contrast, no repetitive shapes or patterns and high level of
                detail
              </p>
            </div>
          ) : (
            <div className="uploaded_content">
              <div className="upload_actions">
                <div className="upload_actions">
                  <p>Loading.. Please wait</p>
                </div>
              </div>
            </div>
          )} */}
          <Form onSubmit={handleSubmit}>
              <Inputwapper>
                <InputLabels labelname="Colazione" />
                <Input
                  name="breakfast"
                  type="text"
                  placeholder="Colazione"
                  value={values.breakfast}
                  error={errors.breakfast}
                  touched={touched.breakfast}
                  onChangeText={(e) => setFieldValue("breakfast", e.target.value)}
                />
              </Inputwapper>
              <Inputwapper>
                <InputLabels labelname="Pranzo" />
                <Input
                  name="lunch"
                  type="text"
                  placeholder="Pranzo"
                  touched={touched.lunch}
                  value={values.lunch}
                  error={errors.lunch}
                  onChangeText={(e) => setFieldValue("lunch", e.target.value)}
                />
              </Inputwapper>
              <Inputwapper>
                <InputLabels labelname="Cena" />
                <Input
                  name="dinner"
                  type="text"
                  placeholder="Cena"
                  touched={touched.dinner}
                  value={values.dinner}
                  error={errors.dinner}
                  onChangeText={(e) => setFieldValue("dinner", e.target.value)}
                />
              </Inputwapper>
              {/* <ButtonWrapper>
                <NavButton
                  onClick={() => navigate("/signup")}
                  name="Sign up"
                  style={{ height: '57px'  }} />
              </ButtonWrapper>*/}
              <ButtonWrapper>
                <Button
                  //disabled={isSubmitting}
                  name="Salva"
                  type="submit"
                  style={{ marginTop: 15, height: '57px' }} />
              </ButtonWrapper> 
          </Form>
        </div>
      </div>
    </Modal>
  );
}

const modelStyles = {
  content: {
    top: "50%",
    zIndex: "100",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Form = styled.form`
  width: 100%;
  margin: 30px 0 40px;
  > label {
    margin-bottom: 5px;
  }

  @media only screen and (max-width:1024px){
    flex-direction: column;
    align-items: center;
  }
`;

const Inputwapper = styled.div`
  position:relative;
  display: inline-block;
  border: 1px solid rgba(207, 211, 212, 1);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;
  .error-name{
    position:absolute;
    top:100%;
    padding-left: 15px;
  }
`

const ButtonWrapper = styled.div`
  gap: 26px;
  display: flex;
  overflow: hidden;
  @media only screen and (max-width:1024px){
    width: 100%;
  }
`;