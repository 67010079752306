import * as constant from "../constant/index.js"
  
  let initialState = {
    currentEditOrView: {},
  };
  
  export const childReducer = (state = initialState, action) => {
    switch (action.type) {
      case constant.MANAGE_CHILD_EDIT:
        return {
          ...state,
          currentEditOrView: action.currentEditOrView,
        };
      default:
        return state;
    }
  };
  