import styled from "styled-components";


export const InputLabels = (props) => {

  return (
    <Label>{props.labelname}</Label>
  );
};
const Label = styled.label`
font-size: 14px;
font-weight: 400;
color: #5e6366;
margin: 0;
margin-bottom: 2px;
padding: 0 15px;
  `;