import {
    VERIFY_TOKEN_REQUEST,
    VERIFY_TOKEN_SUCCESS,
  } from "../actions/index.js";
import * as constant from "../constant/index.js"
  
  let initialState = {
    loading: false,
    user: null,
    token: null,
    completedSurvey: null
  };
  
  export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
      case constant.LOGIN_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case constant.LOGIN_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload?.user,
          token: action.payload?.token,
        };
  
      case constant.LOGIN_FAILURE:
        return {
          ...state,
          loading: false,
        };
  
      case constant.VERIFY_TOKEN_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case constant.VERIFY_TOKEN_SUCCESS:
        return {
          ...state,
          user: action.payload?.user,
          token: action.payload?.token,
          completedSurvey: action.payload?.completedSurvey,
          loading: false,
        };
      case constant.CLEAR_STORE:
        return {
          ...state,
          user: null,
          token: null,
        };
      case constant.COMPLETED_SURVEY:
        return {
          ...state,
          completedSurvey: action.payload,
        };
      case constant.MANAGE_CHILD_REG:
        const userdata = state.user;
        userdata.children.map((item, index)=>{
          if(item._id === action.childdata.id){
            userdata.children[index].qualified = true;
            userdata.children[index].userId = action.registerData
          }
        })
        return {
          ...state,
          user: userdata,
        };
      default:
        return state;
    }
  };
  