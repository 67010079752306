import { axiosClient } from "../../utils/axios-client.js";

export const addFood = (payload, token) => {
  let args = {
    url: "/foods/add-food",
    method: "POST",
    data: payload,
    headers: { token },
  };

  return axiosClient(args);
};

export const getSurvey = (payload) => {

  let auth_token = localStorage.getItem("access_token");
  
  const headers = {
    "content-Type": "application/json",
    token: auth_token,
  };

  let args = {
    url: "/limesurvey/surveylist",
    method: "GET",
    headers
  };

  return axiosClient(args);
};

export const getCompletedSurvey = (email) => {
  let auth_token = localStorage.getItem("access_token");
  
  const headers = {
    "content-Type": "application/json",
    token: auth_token,
  };
  let args = {
    url: `/limesurvey/userresponsesbyid/${email}`,
    method: "GET",
    headers
  };

  return axiosClient(args);
};

export const childSurveyList = (email) => {
  let auth_token = localStorage.getItem("access_token");
  
  const headers = {
    "content-Type": "application/json",
    token: auth_token,
  };
  let args = {
    url: `/limesurvey/childsurveylist/${email}`,
    method: "GET",
    headers
  };

  return axiosClient(args);
};


