import React from "react";
import {
    HashRouter,
    Routes,
    Route,
    Navigate,
  } from "react-router-dom";
import { connect } from "react-redux";
import Login from "../pages/login.js";
import SignUp from "../pages/signup.js";
import DashBoard from "../pages/dashboard.js";
import Forgotpassword from "../pages/forgotpassword.js";
import Resetpassword from "../pages/resetpassword.js";
import { mutateUser } from "../redux/actions/login.action.js";
import { verifyToken } from "../redux/services/login.service.js";
import SpouseSignUp from "../pages/spouse-signup.js";
import ChildAdd from "../pages/childAdd.js";
import OneTimePassword from "../pages/one-time-password.js";
import SpouseChildSelect from "../pages/spouse-childselect.js";
import SpouseNoOne from "../pages/spouse-noone.js";
import SpouseOption from "../pages/spouseOption.js";
import Notes from "../pages/notes.js";
import ChildEdit from "../pages/editChild.js"
import SpouseNotes from "../pages/SpouseNote.js";
import MobileDashboard from "../mobilePages/mobileDashboard.js";
import MobileCalender from "../mobilePages/mobileCalender.js";
import MobileFoodDiary from "../mobilePages/mobileFoodDiary.js";
import MobileNews from "../mobilePages/mobileNews.js";
import MobileQuestionary from "../mobilePages/mobileQuestionary.js";
import MobileEditChild from "../mobilePages/mobileEditChild.js";
//import PageLoader from "../components/pageLoader.js";

class AppRoutes extends React.Component {
    state={
        auth: null,
    }

    async componentDidMount() {
      const auth = await this.getAuth();
      this.setState({ auth });
    }

    getAuth = () => {
        const { token } = this.props;
        if (token) {
          return true;
        } else {
          const local_token = localStorage.getItem("access_token");
          if (local_token) {  
            return verifyToken({ token: local_token })
              .then(async (response) => {
                const { data } = response.data;
                // new HandleAutoLogout({
                //   time: 10 * 60,
                //   onTimeOut: () => {
                //     localStorage.removeItem("access_token");
                //     console.log("Log out trigger");
                //     this.props.dispatch({ type: CLEAR_STORE });
                //     history?.push("/");
                //   },
                // });
                await this.props.dispatch(mutateUser(data));
                return true;
              })
              .catch((error) => {
                localStorage.removeItem("access_token");
                return false;
              });
          } else {
            return false;
          }
        }
      };

    RequireAuth = ({children, redirectTo}) => {
        let isAuthenticated = this.getAuth();
        return isAuthenticated ? children : <Navigate to={"/login"} />;
    }

    render() {
    const {RequireAuth} = this
    //if (this.state.auth == null) return null;
    return (
        <HashRouter {...this.props}>
            <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot-password" element={<Forgotpassword />} />
                <Route path="/reset-password" element={<Resetpassword />} />
                <Route path="/spouse-signup" element={<SpouseSignUp />} />
                <Route path="/child-add" element={<ChildAdd />} />
                <Route path="/verify-account" element={<OneTimePassword />} />
                <Route path="/spouse-selectchild" element={<SpouseChildSelect />} />
                <Route path="/spouse-noone" element={<SpouseNoOne />} />
                <Route path="/spouse-option" element={<SpouseOption />} />
                <Route path="/note" element={<Notes />} />
                <Route path="/spousenote" element={<SpouseNotes />} />
                <Route exact path="/" element={<RequireAuth><DashBoard /></RequireAuth>} />
                <Route exact path="/home" element={<RequireAuth><MobileDashboard /></RequireAuth>} />
                <Route exact path="/calender" element={<RequireAuth><MobileCalender /></RequireAuth>} />
                <Route exact path="/food-diary" element={<RequireAuth><MobileFoodDiary /></RequireAuth>} />
                <Route exact path="/news" element={<RequireAuth><MobileNews /></RequireAuth>} />
                <Route exact path="/questionary" element={<RequireAuth><MobileQuestionary /></RequireAuth>} />
                <Route exact path="/m-child-edit" element={<RequireAuth><MobileEditChild /></RequireAuth>} />
                <Route exact path="/child-edit" element={<RequireAuth><ChildEdit /></RequireAuth>} />
            </Routes> 
        </HashRouter>
    );
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.auth.user,
      token: state.auth.token,
    };
  };

export default connect(mapStateToProps)(AppRoutes);