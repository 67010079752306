import Calendar from 'react-calendar';
import { styled } from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { BiTrash, BiPencil, BiLineChart } from "react-icons/bi/index.esm.js";
//import 'react-calendar/dist/Calendar.css';
//import { CircularProgressbar } from 'react-circular-progressbar';
import '../style/calendar.css';
//import FoodLogo from "../Fooddiary.png"
import { Logout } from "../components/Logout.js";
import NewsList from "../components/NewsList.js";
import 'react-circular-progressbar/dist/styles.css';
import { Button } from "../components/buttons/button.js";
// import Timeline from "../components/timeline/Timeline.js";
// import FoodModal from "../components/timeline/FoodModal.js";
import { getNews } from "../redux/services/news.service.js";
//import { getFood } from "../redux/services/food.service.js";
import { getSurvey } from "../redux/services/survey.service.js";
import ChildRegModal from "../components/modal/ChildRegModal.js";
import ChildEditModal from "../components/modal/ChildEditModal.js";
import { handleEditOrView } from "../redux/actions/login.action.js";
import ProgressListing from "../components/table/MyProgressTable.js";
import { Wrapper, Logo, LogoImage, BackGroundImage } from "../style/styled.js";
import { getCompletedSurvey, childSurveyList } from "../redux/services/survey.service.js";

const DashBoard = (props) => {
  const { user, completedSurvey } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isChild = (user?.age !== undefined)
  const [value, onChange] = useState(new Date());
  //const [displayFood, setDisplayFood] = useState();
  const [showChildReg, toggleChildReg] = useState(false);
  const [selectedChild, setSelectedChild] = useState("");
  const [surveyList, setSurveyList] = useState();
  const [newsList, setNewsList] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [childSurvey, setChildSurvey] = useState();
  let limeSurveyUrl = "https://neuromedspa.limesurvey.net/"

  // if(isChild){
  //   if(user.age > 0 && user.age < 12){ limeSurveyUrl = "https://juniorchild.limesurvey.net/"}
  //   else if(user.age > 11 && user.age < 19){ limeSurveyUrl = "https://juniorchild.limesurvey.net/" }
  // }

  const handleEditChild = (item) => {
    dispatch(handleEditOrView(item, navigate))
  }

  const handleDeleteChild = (item) => {
    //dispatch(handleEditOrView(item, navigate))
  }

  const handleShowSurvey = async (item) => {
    const comSurvey = await childSurveyList(item.userId);
    setChildSurvey(comSurvey?.data?.data)
    setShowEditModal(true)
  }
  
  const takeToSurvey = async (item) => {
    window.open(`${limeSurveyUrl}${item.sid}?token=${user?.email?.replace(/[^a-zA-Z0-9_ ]/g, "")}&lang=it`, "_blank");
  }

  useEffect(()=>{
    value.setHours(0);
    value.setMinutes(0);
    value.setSeconds(0);
    value.setMilliseconds(0);
    if(user){
      const data = {
        user_id: user?._id,
        date: value
      } 
      // getFood(data).then((response) => {
      //   setDisplayFood(response.data?.data)
      // });
      getSurvey(data).then((response) => {
        setSurveyList(response.data?.data)
      });
      getNews(data).then((response) => {
        setNewsList(response.data?.data)
      });
    }
  }, [value, user?._id])
  return (
    <Wrapper style={{ flexDirection: "column" }}>
      <LogoImage style={{ marginBottom: "0vh" }}>
        <Logo src={"/images/logo.png"} />
      </LogoImage>
      <Logout/>
      {/* <button>Logout</button> */}
      <BackGroundImage src={"/images/background.png"}/>
      <Innerwapper>
        <UpperSide>
          <LeftSide>
            <ProfileSec>
              <SectionHeader>Profilo</SectionHeader>
              <Profile>
                <Username>{user?.name} {user?.surname}</Username>
                <FamilyCode>Codice Famiglia: {user?.familycode}</FamilyCode>
              </Profile>
            </ProfileSec>
            {!isChild &&<ChildSection>
              <SectionHeader>Abilita figli:</SectionHeader>
              <ChildList>
              {
                user?.children?.map((item, index)=>{
                  if(item.selected)
                    return(
                      <NameDiv key={index}>
                        <ChildName>{item.childname}</ChildName>
                        {item.qualified ? 
                          <Button name="Abilitato" style={{borderRadius: "10px", height: "4vh", backgroundColor: "#408562", minWidth: "100px", padding: "0 10px", border: "1px solid #408562"}}/>: 
                          <Button name="Non abilitato" style={{borderRadius: "10px", height: "4vh", backgroundColor: "#e43535", padding: "0 10px", minWidth: "100px",  border: "1px solid #e43535"}} onClick={()=>{setSelectedChild(item); toggleChildReg(true)}}/> 
                        }
                        <ActionDiv style={{minWidth:"80px"}}>
                          {item.qualified && <BiPencil onClick={(e)=>{handleEditChild(item);}} style={{cursor: "pointer"}}/>}
                          <BiTrash onClick={(e)=>{handleDeleteChild(item);}} style={{cursor: "pointer"}}/>
                          {item.qualified && <BiLineChart onClick={(e)=>{handleShowSurvey(item)}} style={{cursor: "pointer"}}/>}
                        </ActionDiv>
                      </NameDiv>
                    )
                  })
              }
              <ChildRegModal showModel={showChildReg} toggleModel={toggleChildReg} selectedChild={selectedChild} parentDetail={{"familyId": user?.familyId}}/>
              <ChildEditModal showModel={showEditModal} toggleModel={setShowEditModal} childSurvey={childSurvey}/>
              </ChildList>
            </ChildSection>}
            {!isChild && user?.maritalstatus === "Married" && <SpouseSection>
              <SectionHeader>Coniuge</SectionHeader>
              <SpouseName>{user?.spouse?.name} {user?.spouse?.surname}</SpouseName>
            </SpouseSection>}
          </LeftSide>
          <RightSide style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
            <QuestionSec>
              <SectionHeader>Questionari</SectionHeader>
              <QueProgress>
                {/* <CircularProgressbar style={{path: { height: "10vh"},}} /> */}
              { surveyList && surveyList.map((item, index)=>{
                if(item.active === "Y")
                  return(
                    <SurveyDiv key={index} style={{padding: "0.1vh"}}>
                      <ChildName>{item.surveyls_title}</ChildName> 
                      {/* <a key={index} href={`${limeSurveyUrl}${item.sid}?token=${user?.email?.replace(/[^a-zA-Z0-9_ ]/g, "")}&lang=it`} target="_blank" rel="noopener noreferrer">visualizzazione</a> */}
                      <Button name="visualizzazione" style={{borderRadius: "10px", height: "36px", backgroundColor: "#408562", minWidth: "114px", padding: "0 5px", border: "1px solid #408562"}} onClick={()=>{ takeToSurvey(item)}}/>
                    </SurveyDiv>
                  )
                })
              }
              </QueProgress>
            </QuestionSec>
            
          </RightSide>
        </UpperSide>
        <LowerSide>
          <ProgresSec>
            <SectionHeader>Questionari</SectionHeader>
            <ProgressListing completedSurvey={completedSurvey}/>
          </ProgresSec>
          {/* <NewsSection>
            <SectionHeader>News</SectionHeader>
            <NewsList data={newsList}/>
          </NewsSection> */}
        </LowerSide>
      </Innerwapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.auth?.user,
    completedSurvey: state?.auth?.completedSurvey
  };
};
export default connect(mapStateToProps)(DashBoard);

const Innerwapper = styled.div`
  padding: 30px;
  width: 90%;
  height: 80vh;
  box-sizing: border-box;
  background-color: #ffffff9f;
  border-radius: 25px;
  box-shadow: 0 1px 10px 2px #0000002b;
  max-height: 90vh;
  overflow: auto;
  position: fixed;
  top: 16vh;
  z-index: 10;
  left: 7vw;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    box-shadow: 0 0px 0px 0px #000;
  }
`;

const LeftSide = styled.div`
  // &:before{
  //   content:"";
  //   position:absolute;
  //   left:0;
  //   right:0;
  //   bottom:0;
  //   top:0;
  //   background-color:#00000060
  // }
  @media only screen and (max-width: 1024px) {
    width: 90%;
  }

  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0;
  right: 0;
  padding: 1% 1% 1% 1%;
  position: relative;
  width: 40%;
`;

const RightSide = styled.div`
  // position:relative;
  // &:before{
  //   content:"";
  //   position:absolute;
  //   left:0;
  //   right:0;
  //   bottom:0;
  //   top:0;
  //   background-color:#00000060
  // }
  @media only screen and (max-width: 1024px) {
    top: 0px;
    left: 0px;
    bottom: 0;
    right: 0;
    padding: 3% 2%;
    position: relative;
    width: 42%;
  }

  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0;
  right: 0;
  padding: 1% 1% 1% 1%;
  position: relative;
  width: 60%;
`;

const UpperSide = styled.div`
  width: 100%;
  display: flex;
  height: 47.5vh;
`;

const ProfileSec = styled.div`
  width: 100%;
  height: 17.6vh;
`

const ChildSection = styled.div`
  width: 100%;
  height: 15.8vh;
`

const SpouseSection = styled.div`
  width: 100%;
  height: 10.6vh;
`

const QuestionSec = styled.div`
  width: 100%;
  height: 32vh;
`

const NewsSection = styled.div`
  width: 100%;
  height: 12.95vh;
  padding: 0 1%;
`
const ProgresSec = styled.div`
  width: 100%;
  padding: 0 1%;
`
const QueProgress = styled.div`
  width: 96%;
  padding: 0 2%;
  height: calc(47.5vh - 50px);
  overflow: auto;
`
const Profile = styled.div`
  width: 100%;
  height: calc(17.6vh - 31px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`
const Username = styled.p`
  font-size: calc(1.1 * (1.5vh + 1.1vw));
  font-weight: 600;
  margin: 2vh 0;
`;

const FamilyCode = styled.p`
  font-size: calc(0.7 * (1.5vh + 1.1vw));
  font-weight: 600;
  margin: 0 0;
`;
const ChildList = styled.div`
  height: 11.5vh;
  overflow: auto;
`;
const SectionHeader = styled.div`
  background: #02549b ;
  color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
`
const LowerSide = styled.div`
  width: 100%;
  //height: 32vh;
  display: flex;
`;
const NameDiv = styled.div`
  width: 90%;
  padding: 0.5vh;
  height: 4vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SurveyDiv = styled.div`
  //width: 90%;
  padding: 1vh;
  min-height: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionDiv = styled.div`
  width: 15%;
  padding: 1vh;
  height: 4vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SpouseName = styled.div`
  display: flex;
  height: 7.3vh;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
`
const ChildName = styled.div`
  min-width: 100px;
`;