import styled from "styled-components";


export const MobileHeader = (props) => {
  return (
    <HeaderDiv>
        <FamilyCodeDiv>
            <FamilyCodeContainer>
                <FamilyCode>Codice famiglia:</FamilyCode>
                <FamilyCode>{props.familycode}</FamilyCode>
            </FamilyCodeContainer>
        </FamilyCodeDiv>
        <NameDiv>
            <UserName>
                {props?.username}
            </UserName>
        </NameDiv>
    </HeaderDiv>
  );
};

const HeaderDiv = styled.div`
    width: 100%;
    height: 20vh;
    background: #7CBD4C;
    border-radius:0 0 40px 40px ;
`;

const FamilyCodeDiv = styled.div`
    width: 100vw;
    height: 14vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const FamilyCodeContainer = styled.div`
    width: 60vw;
    height: 9vh;
    background: #02549B;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;
const FamilyCode = styled.div`
    font-size: calc(1.1 * (1.5vh + 1.1vw));
    font-weight: 700;
    padding: 0.5vh 2vw;
    width: 56vw;
    display: flex;
    justify-content: center;
    color: white;
`
const NameDiv = styled.div`
    position: absolute;
    top: 14vh;
    color: white;
`;
const UserName = styled.div`
    font-size: calc(1.1 * (1.5vh + 1.1vw));
    font-weight: 700;
    position: absolute;
    height: 6vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
`
