//import { toast } from 'react-toastify'
import swal from 'sweetalert';

const options = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const apiEffectError = (props) => {
    //return toast.error(props?.message || "Something went wrong.", {...options})
    return swal({
        text: props?.message || "Riprova. Probabilmente ancora non sei registrato o la mail/password sono errate."
    });
}

export const apiEffectSuccess = (props) => {
    //return toast.success(props?.message || "Success!", {...options})
    console.log(props)
    return swal({
        text: props?.message || "Success!",
        timer: 5000
    });
}