import { axiosClient } from "../../utils/axios-client.js";

export const addFood = (payload, token) => {
  let args = {
    url: "/foods/add-food",
    method: "POST",
    data: payload,
    headers: { token },
  };

  return axiosClient(args);
};

export const getFood = (payload) => {
  let args = {
    url: "/foods/get-food",
    method: "POST",
    data: payload,
  };

  return axiosClient(args);
};
