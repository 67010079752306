import styled from "styled-components";
import moment from "moment";

const NewsList = (props) => {
    const dataList = props.data?.map((item, index) => {
        return (
            <ListLi key={index}>
                <strong style={{fontSize: "18px", margin: "8px 0"}}>{item.title}</strong>
                <p style={{fontSize: "10px", margin: "0"}}>{item.description}</p>
                <p style={{fontSize: "8px", margin: "8px 0 0 0"}}>{moment(item.createdAt).format("DD-MM-YYYY")}</p>
            </ListLi>
        );
    });

    return (
        <List>{dataList}</List>
    );
};

export default NewsList;

const List = styled.ol`
    height: calc(26vh - 31px);
    overflow: auto;
    margin: 0;
    padding-left: 2vw;
    @media only screen and (max-width: 1024px) {
        height: calc(40vh - 31px);
        padding-left: 6vw;
        width: 80vw;
        margin-top: 2vh;
    }
`;
const ListLi = styled.li`

`;
const NewsDate = styled.li`
    font-size: 16px
`;