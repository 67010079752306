import styled from "styled-components";

export const Button = (props) => {
  const { disabled, onClick, type, className } = props;
  return (
    <StyledButton className={className} onClick={onClick} disabled={disabled} type={type} {...props}>
      {props.ShowIcon} {props.name}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  min-width: 140px;
  height: 46px;
  padding: 0 30px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: #02549b;
  border: 1px solid #02549b;
  box-sizing: border-box;
  text-transform: capitalise;
  cursor: pointer;
  font-weight:bold;
  z-index: 15;
  :hover {
    filter: brightness(0.5);
  }


  @media only screen and (max-width:1024px){
    width: 100%;
  }
`;
