import * as Yup from "yup";

export const spouseEmailSchema = Yup.object().shape({
    isChecked: Yup.string(),
    spousemail: Yup.string()
      .when("isChecked", (isChecked, schema) => {
        if(isChecked[0] === 'false'){
          return schema.required("Questo campo è obbligatorio").email("Inserire una email valida, grazie")
        }
        return schema
      })
});

export const spouseWpSchema = Yup.object().shape({
  spousewp: Yup.string()
    .test('len', 'Il numero di telefono deve contenere 10 cifre', (val) => { if(val) return val.toString().length === 12; })
    .required("Il numero di telefono è obbligatorio.")
    .trim()
    .matches(
      /^(\+\d{1,3}[- ]?)?\d{6,12}$/gm,
      "Si prega di inserire un numero di telefono valido"
    ),
});

export const CreateUserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Sono consentiti minimo 2 e massimo 15 caratteri.")
    .max(15, "Sono consentiti minimo 2 e massimo 15 caratteri.")
    .matches("^[a-zA-Z][a-zA-Z\\s]+$", "Inserisci solo caratteri.")
    .required("Questo campo è obbligatorio."),
  surname: Yup.string()
    .min(2, "Sono consentiti minimo 2 e massimo 15 caratteri.")
    .max(15, "Sono consentiti minimo 2 e massimo 15 caratteri.")
    .matches("^[a-zA-Z][a-zA-Z\\s]+$", "Inserisci solo caratteri.")
    .required("Questo campo è obbligatorio."),
  // username: Yup.string()
  //   .min(2, "Sono consentiti minimo 2 e massimo 15 caratteri.")
  //   .max(15, "Sono consentiti minimo 2 e massimo 15 caratteri.")
  //   .matches("^[a-z0-9]+([._]?[a-z0-9]+)*$", "Inserisci un nome utente valido.")
  //   .required("Questo campo è obbligatorio."),
  email: Yup.string()
    .max(35, "Non è possibile inserire più di 35 caratteri.")
    .email("Si prega di inserire un indirizzo email valido.")
    .required("Questo campo è obbligatorio."),
  password: Yup.string()
    .min(8, "Sono consentiti minimo 8 e massimo 15 caratteri.")
    .max(15, "Sono consentiti minimo 8 e massimo 15 caratteri.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "La password deve contenere un carattere maiuscolo, minuscolo e speciale e un numero."
    )
    .required("Questo campo è obbligatorio."),
  phone_number: Yup.string()
    .length(10, "Il numero di telefono deve essere di 10 cifre")
    .matches(
      /^[0-9]{10}?$/,
      "Il numero deve avere il prefisso internazionale."
    )
    .required("Questo campo è obbligatorio."),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Le password devono coincidere.")
    .min(8, "Sono consentiti minimo 8 e massimo 15 caratteri.")
    .max(15, "Sono consentiti minimo 8 e massimo 15 caratteri.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Il numero deve avere il prefisso internazionale."
    )
    .required("Questo campo è obbligatorio."),
  sex: Yup.string().test("sex","Seleziona il tuo sesso.",
    function (value) {
      const { sex } = this.parent;
      if (sex === "none") return false
      return true
    }),
  maritalstatus: Yup.string().test("maritalstatus","Seleziona il tuo stato civile.", 
    function (value) {
      const { maritalstatus } = this.parent;
      if (maritalstatus === "none") return false
      return true
    }), 
  isChecked: Yup.string().test("isChecked","Devi accettare i termini di servizio.", 
    function (value) {
      const { isChecked } = this.parent;
      if (isChecked === "false"){ return false }
      return true
    }),
});

export const CreateSpouseSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Sono consentiti minimo 2 e massimo 15 caratteri.")
    .max(15, "Sono consentiti minimo 2 e massimo 15 caratteri.")
    .matches("^[a-zA-Z][a-zA-Z\\s]+$", "Inserisci solo caratteri.")
    .required("Questo campo è obbligatorio."),
  surname: Yup.string()
    .min(2, "Sono consentiti minimo 2 e massimo 15 caratteri.")
    .max(15, "Sono consentiti minimo 2 e massimo 15 caratteri.")
    .matches("^[a-zA-Z][a-zA-Z\\s]+$", "Inserisci solo caratteri.")
    .required("Questo campo è obbligatorio."),
  // username: Yup.string()
  //   .min(2, "Sono consentiti minimo 2 e massimo 15 caratteri.")
  //   .max(15, "Sono consentiti minimo 2 e massimo 15 caratteri.")
  //   .matches("^[a-z0-9]+([._]?[a-z0-9]+)*$", "Inserisci un nome utente valido.")
  //   .required("Questo campo è obbligatorio."),
  email: Yup.string()
    .max(35, "Non è possibile inserire più di 35 caratteri.")
    .email("Si prega di inserire un indirizzo email valido.")
    .required("Questo campo è obbligatorio."),
  phone_number: Yup.string()
    .min(8, "Sono consentiti minimo 8 e massimo 15 caratteri.")
    .max(15, "Sono consentiti minimo 8 e massimo 15 caratteri.")
    .matches(
      /^[0-9]{10}?$/,
      "Il numero deve avere il prefisso internazionale."
    )
    .required("Questo campo è obbligatorio."),
  password: Yup.string()
    .min(8, "Sono consentiti minimo 8 e massimo 15 caratteri.")
    .max(15, "Sono consentiti minimo 8 e massimo 15 caratteri.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "La password deve contenere un carattere maiuscolo, minuscolo e speciale e un numero."
    )
    .required("Questo campo è obbligatorio."),
  sex: Yup.string().test("sex","Seleziona il tuo sesso.",
    function (value) {
      const { sex } = this.parent;
      if (sex === "none") return false
      return true
    }),
  maritalstatus: Yup.string().test("maritalstatus","Seleziona il tuo stato civile.", 
    function (value) {
      const { maritalstatus } = this.parent;
      if (maritalstatus === "none") return false
      return true
    }), 
  isChecked: Yup.string().test("isChecked","Devi accettare i termini di servizio.", 
    function (value) {
      const { isChecked } = this.parent;
      if (isChecked === "false"){ return false }
      return true
    }),
});

export const SelectChildSchema = Yup.object().shape({
  isChecked: Yup.string().test("isChecked","Devi accettare i termini di servizio.", 
    function (value) {
      const { isChecked } = this.parent;
      if (isChecked === "false") return false
      return true
    }),
});

export const SpouseOptionSchema = Yup.object().shape({
  picked: Yup.string().test("picked","È necessario selezionare un'opzione per procedere.", 
    function (value) {
      const { picked } = this.parent;
      if (!picked){ return false}
      return true
    }),
});

export const EditChildSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Sono consentiti minimo 8 e massimo 15 caratteri.")
    .max(15, "Sono consentiti minimo 8 e massimo 15 caratteri.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "La password deve contenere un carattere maiuscolo, minuscolo e speciale e un numero."
    )
});