import React, { useState } from "react";
import { useFormik } from "formik";
import { MdOutlineClose } from "react-icons/md/index.esm.js";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { InputLabels } from "../inputs/label.js";
import { Input } from "../inputs/input.js";
import styled from "styled-components";
import { Button } from "../buttons/button.js";
import "../timeline/foodModel.css"
//import { childRegistarion } from "../../redux/services/login.service.js";
import { childRegAction } from "../../redux/actions/login.action.js";
import { useDispatch } from "react-redux";

export default function ChildRegModal(props) {
  const { showModel, toggleModel } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const [successMsg, setSuccessMsg] = useState("");
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      name: props?.selectedChild?.childname,
      id: props?.selectedChild?._id,
      age: props?.selectedChild?.age,
      familyId: props?.parentDetail?.familyId
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setSubmitting(true);
      setSuccessMsg("")
      dispatch(childRegAction(values, setSuccessMsg, errors, setSubmitting))
      // .then((res)=>{
      //   setSubmitting(false);
      //   toast.success("bambino registrato.")
      //   console.log(res)
      //   console.log(res?.data?.message)
      //   setSuccessMsg(res?.data?.message)
      //   //toggleModel();
      // })
      // .catch((error) => {
      //   errors.username = error?.response?.data?.message
      //   toast.error(error?.message);
      //   setSubmitting(false);
      //   console.log(error)
      // });
    },
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    setSubmitting,
    isSubmitting
  } = formik;

  return (
    <Modal isOpen={showModel} style={modelStyles} ariaHideApp={false}>
      <div className="model_head">
        <button onClick={() => toggleModel()}>
          <MdOutlineClose />
        </button>
      </div>
      <div className="upload_model_wrap">
        <div>{props?.selectedChild?.childname}</div>
        <div className="upload_inner">
          <Form onSubmit={handleSubmit}>
              <Inputwapper>
                <InputLabels labelname="Username" />
                <Input
                  name="username"
                  type="text"
                  placeholder="Username figlio"
                  value={values.username}
                  error={errors.username}
                  touched={touched.username}
                  onChangeText={(e) => setFieldValue("username", e.target.value)}
                />
              </Inputwapper>
              <Inputwapper>
                <InputLabels labelname="Password" />
                <Input
                  name="password"
                  type="password"
                  placeholder="****"
                  touched={touched.password}
                  value={values.password}
                  error={errors.password}
                  onChangeText={(e) => setFieldValue("password", e.target.value)}
                />
              </Inputwapper>
              {/* <SuccessMsg className="error-name" style={{ marginTop: "-7px" }}>
                  {successMsg}
              </SuccessMsg> */}
              <ButtonWrapper>
                <Button
                  //disabled={isSubmitting}
                  name="Registra"
                  type="submit"
                  style={{ marginTop: 15, height: '57px' }} />
              </ButtonWrapper> 
          </Form>
        </div>
      </div>
    </Modal>
  );
}

const modelStyles = {
  content: {
    top: "50%",
    zIndex: "100",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Form = styled.form`
  width: 100%;
  margin: 30px 0 40px;
  > label {
    margin-bottom: 5px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width:1024px){
    flex-direction: column;
    align-items: center;
  }
`;

const Inputwapper = styled.div`
  position:relative;
  display: inline-block;
  border: 1px solid rgba(207, 211, 212, 1);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;
  .error-name{
    position:absolute;
    top:100%;
    padding-left: 15px;
  }
`

const ButtonWrapper = styled.div`
  gap: 26px;
  display: flex;
  overflow: hidden;
  @media only screen and (max-width:1024px){
    width: 100%;
  }
`;

const SuccessMsg = styled.div`
  color: green;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: -15px;
`;