import { useFormik } from "formik";
import { styled } from "styled-components";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "../style/calendar.css";
import { Input } from "../components/inputs/input.js";
import { Logout } from "../components/Logout.js";
import { InputLabels } from "../components/inputs/label.js";
import "react-circular-progressbar/dist/styles.css";
import { Button } from "../components/buttons/button.js";
import { Wrapper, Logo, LogoImage, BackGroundImage } from "../style/styled.js";
import { updateChild } from "../redux/services/login.service.js";
import { useNavigate } from "react-router-dom";
import { EditChildSchema } from "../validations/signupValidation.js";

const ChildEdit = (props) => {
  const { childDetail } = props;
  const dispatch = useDispatch();
  const [successMsg, setSuccessMsg] = useState("");
  const navigate = useNavigate();

  

  const formik = useFormik({
    initialValues: {
      name: childDetail.name,
      surname: childDetail.surname,
      username: childDetail?.username,
      password: "",
      _id: childDetail?._id
    },
    enableReinitialize: true,
    validationSchema: EditChildSchema,
    onSubmit: (values) => {
      // setSubmitting(true);
      updateChild(values)
      .then((res)=>{
        setSubmitting(false);
        //toast.success("bambino registrato.")
        setSuccessMsg(res?.data?.message)
        //toggleModel();
      })
      .catch((error) => {
        errors.username = error?.response?.data?.message
        //toast.error(error?.message);
        setSubmitting(false);
      });
    },
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    setSubmitting,
    isSubmitting,
  } = formik;

  useEffect(() => {}, []);

  return (
    <Wrapper style={{ flexDirection: "column" }}>
      <LogoImage style={{ marginBottom: "0vh" }}>
        <Logo src={"/images/logo.png"} />
      </LogoImage>
      <Logout />
      {/* <button>Logout</button> */}
      <BackGroundImage src={"/images/background.png"} />
      <Innerwapper>
        <Button 
            name="Indietro"
            style={{ marginTop: 15, height: "57px" }}
            onClick={()=> navigate('/')}
        />
        <Form onSubmit={handleSubmit}>
          <InlineBlock>
            <InnerLeft>
              <Inputwapper>
                <InputLabels labelname="Name" />
                <Input
                  name="name"
                  type="text"
                  placeholder="Name figlio"
                  value={values.name}
                  error={errors.name}
                  touched={touched.name}
                  onChangeText={(e) => setFieldValue("name", e.target.value)}
                />
              </Inputwapper>
            </InnerLeft>
            <InnerRight>
              <Inputwapper>
                <InputLabels labelname="Surname" />
                <Input
                  name="surname"
                  type="text"
                  placeholder="surname figlio"
                  value={values.surname}
                  error={errors.surname}
                  touched={touched.surname}
                  onChangeText={(e) => setFieldValue("surname", e.target.value)}
                />
              </Inputwapper>
            </InnerRight>
          </InlineBlock>
          <Inputwapper>
            <InputLabels labelname="Username" />
            <Input
              name="username"
              type="text"
              placeholder="Username figlio"
              value={values.username}
              error={errors.username}
              touched={touched.username}
              onChangeText={(e) => setFieldValue("username", e.target.value)}
            />
          </Inputwapper>
          <Inputwapper>
            <InputLabels labelname="Password" />
            <Input
              name="password"
              type="password"
              placeholder="****"
              touched={touched.password}
              value={values.password}
              error={errors.password}
              onChangeText={(e) => setFieldValue("password", e.target.value)}
            />
          </Inputwapper>
          <SuccessMsg className="error-name" style={{ marginTop: "-7px" }}>
            {successMsg}
          </SuccessMsg>
          <ButtonWrapper>
            <Button
              //disabled={isSubmitting}
              name="Salva"
              type="submit"
              style={{ marginTop: 15, height: "57px" }}
            />
          </ButtonWrapper>
        </Form>
      </Innerwapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    childDetail: state?.childReducer?.currentEditOrView,
  };
};
export default connect(mapStateToProps)(ChildEdit);

const Innerwapper = styled.div`
  padding: 30px;
  width: 90%;
  height: 80vh;
  box-sizing: border-box;
  background-color: #ffffff9f;
  border-radius: 25px;
  box-shadow: 0 1px 10px 2px #0000002b;
  max-height: 90vh;
  overflow: auto;
  position: fixed;
  top: 16vh;
  z-index: 10;
  left: 7vw;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 1024px) {
    box-shadow: 0 0px 0px 0px #000;
  }
`;

const Form = styled.form`
  width: 60%;
  margin: 30px 0 40px;
  > label {
    margin-bottom: 5px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Inputwapper = styled.div`
  position: relative;
  display: inline-block;
  border: 1px solid rgba(207, 211, 212, 1);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;
  .error-name {
    position: absolute;
    top: 100%;
    padding-left: 15px;
  }
`;
const SuccessMsg = styled.div`
  color: green;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: -15px;
`;

const ButtonWrapper = styled.div`
  gap: 26px;
  display: flex;
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const InlineBlock = styled.div`
@media only screen and (max-width: 1024px) {
  display: flex;
}
width: 100%;
display: flex;
`
const InnerLeft = styled.div`
  @media only screen and (max-width: 1024px) {
    width: 48%;
    padding: 0% 2% 0% 0%;
  }
  width: 48%;
  padding: 0% 2% 0% 0%;
`;
const InnerRight = styled.div`
  @media only screen and (max-width: 1024px) {
    width: 48%;
    padding: 0% 0% 0% 2%;
  }
  width: 48%;
  padding: 0% 0% 0% 2%;
`;