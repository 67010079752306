import * as Yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/inputs/input.js";
import { Button } from "../components/buttons/button.js";
import { InputLabels } from "../components/inputs/label.js";
import { forgotPassword } from "../redux/services/index.js";
import { Wrapper, LoginForm } from "../style/styled.js";
import swal from "sweetalert";

const Email = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("This field is required."),
});

const Forgotpassword = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  useEffect(() => {
    setEmail(location?.email);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: email,
    },
    validationSchema: Email,
    enableReinitialize: true,
    onSubmit: (values) => {
      let data = { email: values?.email };
      forgotPassword(data)
        .then((res) => {
          //toast.success(res?.data?.message);
          swal({
            text: res?.data?.message,
            timer: 5000
          });
          navigate("/reset-password", {state : { email : values?.email}});
        })
        .catch((error) => {
          //toast.error(error?.response?.data?.message);
          swal({
            text: error?.response?.data?.message,
          });
        });
    },
  });
  const { handleSubmit, values, errors, setFieldValue } = formik;

  return (
    <Wrapper>
      <LoginForm>
        <Innerwapper>
          {/* <Heading><Backbtn onClick={() => navigate.push("/")}>
            <BsFillArrowLeftCircleFill size={23} /></Backbtn>Forgot Password</Heading> */}
          <Text>
            Please enter the registered email address to receive the password
            reset instructions
          </Text>
          <Form onSubmit={handleSubmit}>
            <Inputwapper>
              <InputLabels labelname="Email" />
              <Input
                name="email"
                type="email"
                placeholder="Email"
                value={values.email}
                error={errors.email}
                touched={true}
                onChangeText={(e) => setFieldValue("email", e.target.value)}
              />
            </Inputwapper>
            <Button name="Submit" type="submit" style={{ marginTop: 10, float: "right" }} />
          </Form>
        </Innerwapper>
      </LoginForm>
    </Wrapper>
  );
};

export default Forgotpassword;

const Logo = styled.img`
  width: 175px;
  height: auto;
  margin: 30px 0 0 30px;
  z-index: 9;
  position: relative
`;
const BackgroundImg = styled.div`
  background: url(./images/woman-working.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position:right -250px center;
  max-width: 550px;
  width: 40%;
  position:relative;
  &:before{
    content:"";
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    background-color:#00000060
  }
  @media only screen and (max-width:1024px){
    display:none
  }
`;

const Innerwapper = styled.div`
  padding: 30px;
  width: 100%;
  max-width: 580px;
  box-sizing: border-box;
  max-height: 100%;
  background-color:#fff;
  border-radius:6px;
  box-shadow: 0 0 12px #eeebeb;
`;

const Form = styled.form`
  width: 100%;
  margin: 40px 0 0;
  > label {
    margin-bottom: 5px;
  }
`;
const Heading = styled.h1`
  font-size: 30px;
  line-height: 35px;
  color:#0a0b26;
  font-weight: 800;
  margin: 0;
  position:relative;
  padding-left:35px
`;
const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #505258;
  margin: 4px auto 20px;
`;
const Backbtn = styled.span`
  color: #007955;
  padding: 0px;
  height: 46px;
  cursor: pointer;
  position:absolute;
  left:0;
  top:-2px;
`;
const Inputwapper = styled.div`
  position:relative;
  display: inline-block;
  width: 100%;
  .error-name{
    position:absolute;
    top:100%;
    padding-left: 15px;
  }
  `